import { MoreVert, Close, ContentCopy, SellOutlined, JoinLeftOutlined, DeleteOutlined } from '@mui/icons-material';
import {
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    TextField,
    List,
    ListItem,
    ListItemButton,
    ListItemAvatar,
    Avatar,
    Tooltip,
} from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import PoppedControl from '../../../components/formControls/PoppedControl';
import { TableEditorItem } from '../../../components/tableEditor/TableEditor';
import { FormikProps } from 'formik';
import ObsItemFormTagToggle from './ObsItemFormTagToggle';
import { ITableEditorRowActions } from '../../../components/tableEditor/TableEditorRow';
import { ObservationItemFormValues } from '../ObservationFormApp';
import { useJoyride } from '../../../services/joyrideContext';
import ObsItemFormRingMark from './ObsItemFormRingMark';

const ObsItemFormRowSubmenu: FC<{
    item: TableEditorItem<ObservationItemFormValues>;
    formikProps: FormikProps<ObservationItemFormValues>;
    formActions: ITableEditorRowActions;
    formId: string;
    existingGroups?: number[];
    groupsEnabled?: boolean;
    onGroupsEnabled?: () => void;
    onGroupChanged?: (group: number) => void;
}> = (props) => {
    const { item, formikProps, formId, existingGroups, formActions, groupsEnabled, onGroupsEnabled, onGroupChanged } =
        props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const submenuOpen = Boolean(anchorEl);
    const { values, touched, errors, handleBlur, handleChange, setFieldValue } = formikProps;
    const { joyrideEvents } = useJoyride();

    useEffect(() => {
        if (!joyrideEvents) return;

        const subscription = joyrideEvents.subscribe((data) => {
            if (
                data.step.target === '.tour-createitemdetails-2' ||
                data.step.target === '.tour-createitemdetails-3' ||
                data.step.target === '.tour-createitemdetails-4' ||
                data.step.target === '.tour-createitemdetails-5'
            )
                setAnchorEl(buttonRef.current);

            if (data.step.target === '.tour-createitemdetails-6') handleClose();
        });

        return () => subscription.unsubscribe();
    }, [joyrideEvents]);

    const handleOpen = () => {
        setAnchorEl(buttonRef.current);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    if (item.empty) return null;

    const groupsToRender = Array.from(
        { length: Math.max(...(existingGroups?.length ? existingGroups : [0])) + 1 },
        (_, i) => i + 1,
    );

    return (
        <>
            <Tooltip title="Více možností">
                <IconButton
                    id={formId + 'moreOptionsButton'}
                    aria-label="Více možností"
                    aria-controls={submenuOpen ? formId + 'moreOptionsMenu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={submenuOpen ? 'true' : undefined}
                    onClick={handleOpen}
                    ref={buttonRef}
                >
                    <MoreVert />
                </IconButton>
            </Tooltip>
            <Menu
                id={formId + 'moreOptionsMenu'}
                anchorEl={anchorEl}
                open={submenuOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                MenuListProps={{
                    'aria-labelledby': formId + 'moreOptionsButton',
                    className:
                        'tour-createitemdetails-2 tour-createitemdetails-3 tour-createitemdetails-4 tour-createitemdetails-5',
                }}
            >
                <ObsItemFormTagToggle formikProps={formikProps} onClose={handleClose} />
                {!groupsEnabled && (
                    <MenuItem
                        onClick={() => {
                            onGroupsEnabled && onGroupsEnabled();
                        }}
                    >
                        <ListItemIcon>
                            <JoinLeftOutlined fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Zapnout skupiny</ListItemText>
                    </MenuItem>
                )}
                {groupsEnabled && (
                    <PoppedControl
                        renderIcon={() => (
                            <MenuItem>
                                <ListItemIcon>
                                    <JoinLeftOutlined fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Upravit skupinu</ListItemText>
                            </MenuItem>
                        )}
                        renderControl={(handleClose) => (
                            <List
                                sx={{
                                    pt: 0,
                                    '& .MuiListItem-root .MuiButtonBase-root.Mui-selected .MuiAvatar-root': {
                                        bgcolor: 'primary.main',
                                    },
                                }}
                            >
                                {groupsToRender.sort().map((group) => (
                                    <ListItem disableGutters key={group}>
                                        <ListItemButton
                                            selected={values.group == group}
                                            onClick={() => {
                                                setFieldValue('group', group);
                                                onGroupChanged && onGroupChanged(group);
                                                handleClose();
                                            }}
                                        >
                                            <ListItemAvatar>
                                                <Avatar>{group}</Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={`Skupina ${group}`} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                        title="Vyberte skupinu pro zařazení pozorování"
                        confirmButtonLabel="Pokračovat"
                        onPopClose={() => handleClose()}
                        disableButtons
                    />
                )}
                <PoppedControl
                    renderIcon={() => (
                        <MenuItem>
                            <ListItemIcon>
                                <SellOutlined fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Kroužek/značka</ListItemText>
                        </MenuItem>
                    )}
                    renderControl={() => <ObsItemFormRingMark />}
                    title="Kroužkování"
                    description="Vyplňte číslo pozorovaného kroužku, případně zanechte pole prázdné, pokud konkrétní číslo neznáte."
                    confirmButtonLabel="Přidat kroužek"
                    cancelButtonLabel="Odebrat kroužek"
                    disableClickaway
                    onPopClose={() => handleClose()}
                    onConfirmed={() =>
                        // make sure we don't duplicate the ring tag
                        setFieldValue('tags', [...(values.tags || []).filter((t) => t !== 'ringMark'), 'ringMark'])
                    }
                    onCanceled={() => {
                        setFieldValue(
                            'tags',
                            (values.tags || []).filter((tag) => tag !== 'ringMark'),
                        );
                        setFieldValue('ringMark', null);
                    }}
                />
                {!!formActions.duplicateItem && (
                    <MenuItem
                        onClick={() => {
                            formActions.duplicateItem?.();
                            handleClose();
                        }}
                    >
                        <ListItemIcon>
                            <ContentCopy fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Duplikovat pozorování</ListItemText>
                    </MenuItem>
                )}
                {formActions.deleteItem && (
                    <MenuItem
                        onClick={() => {
                            formActions.deleteItem?.();
                            handleClose();
                        }}
                    >
                        <ListItemIcon>
                            <DeleteOutlined fontSize="small" color="error" />
                        </ListItemIcon>
                        <ListItemText>Odebrat pozorování</ListItemText>
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

export default ObsItemFormRowSubmenu;
