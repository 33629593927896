import { ContentCopy } from '@mui/icons-material';
import { IconButton, IconButtonProps, SvgIconProps, Typography, TypographyProps, Tooltip } from '@mui/material';
import { FunctionComponent, cloneElement, useState } from 'react';

const CopyToClipboard: FunctionComponent<{
    label: string;
    value: string;
    copyValue?: string;
    typographyProps?: TypographyProps<any>;
    iconButtonProps?: IconButtonProps;
    iconProps?: SvgIconProps;
    onClick?: () => void;
    onlyIcon?: boolean;
    customIcon?: JSX.Element;
    copiedMessage?: string;
}> = ({
    label,
    value,
    copyValue,
    typographyProps,
    iconButtonProps,
    iconProps,
    onClick,
    onlyIcon,
    customIcon,
    copiedMessage,
}) => {
    const [openTooltip, setOpenTooltip] = useState(false);

    const onCopyClick = () => {
        if (onClick) onClick();
        if (navigator.clipboard) {
            navigator.clipboard.writeText(copyValue ?? value);
            setOpenTooltip(true);
            setTimeout(() => setOpenTooltip(false), 2000);
        } else console.error('Clipboard API not supported');
    };

    const icon = customIcon ? cloneElement(customIcon, iconProps) : <ContentCopy fontSize="inherit" {...iconProps} />;

    return (
        <Typography
            variant="caption"
            component="span"
            {...typographyProps}
            sx={{ ...typographyProps?.sx, pointerEvents: 'all' }}
            className="CopyToClipboard"
        >
            {!onlyIcon && (
                <>
                    <b>{label}:</b>
                    &nbsp;
                    {value}
                    &nbsp;
                </>
            )}
            <Tooltip
                title={copiedMessage ?? 'Zkopírováno do\xa0schánky!'}
                placement="right"
                onClose={() => setOpenTooltip(false)}
                open={openTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
            >
                <IconButton size="small" {...iconButtonProps} onClick={onCopyClick}>
                    {icon}
                </IconButton>
            </Tooltip>
        </Typography>
    );
};

export default CopyToClipboard;
