import {
    MapContainer,
    Marker,
    TileLayer,
    Circle,
    useMapEvents,
    useMap,
    LayersControl,
    ScaleControl,
} from 'react-leaflet';
import React, { FC, useEffect, useRef } from 'react';
import { latLng } from 'leaflet';
import L from 'leaflet';
import { Box, SxProps, useMediaQuery } from '@mui/material';
import { ICoordinates } from '../../schemas/interfaces';
import { useUser } from '../../services/userContext';
import FullScreenMap from './FullscreenMap';

export const OSM_MUNICIPALITY_ZOOM = 14;
export const OSM_DEFAULT_ZOOM = 8;
export const OSM_POINT_REQUIRED_ZOOM = 14;
export const OSM_DEfAULT_CENTER: ICoordinates = [49.95, 15.3386383];
export const OSM_ZOOM_CLICK_STEP = 2;

export enum MapCategory {
    INSERT = 'insert',
    SEARCH = 'search',
}

interface OpenStreetMapProps {
    children?: React.ReactNode;
    height?: string;
    minHeight?: string;
    maxHeight?: string;
    center?: ICoordinates;
    defaultZoom?: number;
    sx?: SxProps;
    mapCategory?: MapCategory;
    hideScale?: boolean;
    enableFullscreen?: boolean;
}

const OpenStreetMap: React.FunctionComponent<OpenStreetMapProps> = (props) => {
    const user = useUser();
    const [tileLayer, setTileLayer] = React.useState<string | undefined>();
    const [isFullscreen, setIsFullscreen] = React.useState<boolean>(false);
    const isMobile = useMediaQuery('(pointer: coarse)');

    useEffect(() => {
        if (!user) return;

        setTileLayer(getTileLayer());
    }, [user]);

    const getTileLayer = () => {
        if (!props.mapCategory) return user?.maps.default;

        const savedTileLayer = localStorage.getItem(`${props.mapCategory}_tileLayer`);

        // make sure the saved layer is in the list of available layers, otherwise use default
        return user?.maps.baseLayers.find((l) => l.label === savedTileLayer)?.label ?? user?.maps.default;
    };

    const saveTileLayer = (label: string) => {
        if (!props.mapCategory) return;

        if (label === user?.maps.default && !localStorage.getItem(`${props.mapCategory}_tileLayer`)) return;

        setTileLayer(label);
        localStorage.setItem(`${props.mapCategory}_tileLayer`, label);
    };

    return (
        <Box
            className="OpenStreetMap"
            sx={{
                ...props.sx,
                height: props.height || '60vh',
                minHeight: props.minHeight || '300px',
                maxHeight: props.maxHeight || '500px',
                width: '100%',
                overflow: 'hidden',
                '& .marker-cluster-small': {
                    backgroundColor: 'rgba(218, 229, 239, 0.6)',
                    '& div': { backgroundColor: 'rgba(166, 192, 206, 0.7)' },
                },
                '& .marker-cluster-medium': {
                    backgroundColor: 'rgba(248, 188, 136, 0.6)',
                    '& div': { backgroundColor: 'rgba(241, 136, 80, 0.7)' },
                },
                '& .dimmed': {
                    filter: 'brightness(0.5)',
                    opacity: 0.5,
                    cursor: 'grab',
                },
                '& .leaflet-top, & .leaflet-bottom': {
                    zIndex: 450,
                },
                '& .leaflet-container': {
                    maxHeight: 'none !important',
                },
                ...(isFullscreen && {
                    position: 'fixed',
                    top: isMobile ? '56px' : '64px',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 5000,
                    height: '100vh',
                    width: '100%',
                    maxHeight: '100vh',
                    maxWidth: '100vw',
                    '& .leaflet-container': {
                        minHeight: '100% !important',
                        maxHeight: '100% !important',
                        height: '100% !important',
                    },
                }),
                '& .leaflet-control-layers-toggle': {
                    width: '30px',
                    height: '30px',
                    backgroundSize: '18px',
                },
            }}
        >
            <MapContainer
                center={props.center || OSM_DEfAULT_CENTER}
                zoom={props.defaultZoom || OSM_DEFAULT_ZOOM}
                scrollWheelZoom={true}
                style={{
                    minHeight: isFullscreen ? '100vh' : props.minHeight || '300px',
                    height: isFullscreen ? '100vh' : props.height || '60vh',
                    maxHeight: isFullscreen ? '100vh' : props.maxHeight || '500px',
                }}
                zoomSnap={0.1}
                zoomDelta={0.5}
                maxZoom={18}
                preferCanvas
            >
                <OSMCenterZoom
                    center={props.center || OSM_DEfAULT_CENTER}
                    zoom={props.defaultZoom || OSM_DEFAULT_ZOOM}
                />
                <LayersControl position="topright">
                    {user?.maps.baseLayers.map((layer: any) => (
                        <LayersControl.BaseLayer
                            name={layer.name.cs}
                            checked={layer.label === tileLayer}
                            key={layer.label}
                        >
                            <TileLayer
                                attribution={layer.options.attribution}
                                url={layer.url}
                                eventHandlers={{
                                    add: () => saveTileLayer(layer.label),
                                }}
                            />
                        </LayersControl.BaseLayer>
                    ))}

                    {/* {user?.maps.overlays.map((layer: any) => (
                        <LayersControl.Overlay name={layer.name.cs}>
                            <TileLayer key={layer.label} url={layer.url} />
                        </LayersControl.Overlay>
                    ))} */}
                </LayersControl>
                {props.enableFullscreen && (
                    <FullScreenMap onEnable={() => setIsFullscreen(true)} onDisable={() => setIsFullscreen(false)} />
                )}
                {!props.hideScale && <ScaleControl position="bottomleft" imperial={false} />}
                {props.children}
            </MapContainer>
        </Box>
    );
};

export const OSMOnClickLocationMarker: React.FunctionComponent<{
    onClick?: (latitude: number, longitude: number) => void;
    latitude?: number;
    longitude?: number;
    radius?: number;
    requiredZoom?: number;
    draggable?: boolean;
}> = (props) => {
    const position = props.latitude && props.longitude ? latLng(props.latitude, props.longitude) : undefined;
    const map = useMap();
    const markerRef = useRef(null);

    useMapEvents({
        click(e) {
            if (props.requiredZoom && map.getZoom() < props.requiredZoom)
                return map.setView(
                    [e.latlng.lat, e.latlng.lng],
                    Math.min(map.getZoom() + OSM_ZOOM_CLICK_STEP, props.requiredZoom),
                );

            props.onClick?.(e.latlng.lat, e.latlng.lng);
        },
    });

    const markerEventHandlers = {
        dragend() {
            if (!props.draggable || !markerRef.current) return;
            const latlng = (markerRef.current as any).getLatLng();
            props.onClick?.(latlng.lat, latlng.lng);
        },
    };

    if (position && props.radius) {
        map.fitBounds(position.toBounds(props.radius * 2 * 1000), { padding: [20, 20] });
    } else if (position) {
        map.flyTo(position, map.getZoom());
    }

    return (
        (position && (
            <>
                <Marker
                    position={position}
                    icon={customIcon}
                    ref={markerRef}
                    draggable={!!props.draggable}
                    eventHandlers={markerEventHandlers}
                />
                {props.radius && (
                    <Circle
                        center={position}
                        pathOptions={{ fillColor: '#5E7694', fillOpacity: 0.3, color: '#5E7694' }}
                        radius={props.radius * 1000}
                    />
                )}
            </>
        )) ||
        null
    );
};

export const OSMCenterZoom: FC<{ center: ICoordinates; zoom: number }> = (props) => {
    const map = useMap();

    useEffect(() => {
        if (!props.center[0] || !props.center[1]) return;

        if (props.center[0] === OSM_DEfAULT_CENTER[0] && props.center[1] === OSM_DEfAULT_CENTER[1]) {
            map.setView(props.center, OSM_DEFAULT_ZOOM);
            return;
        }

        map.setView(props.center, map.getZoom() > props.zoom ? map.getZoom() : props.zoom);
    }, [props.center, props.zoom]);

    return null;
};

const AvifIcon = L.Icon.extend({
    options: {
        iconUrl: require('../../apps/ObsListsApp/components/tabs/map/location.svg').default,
        iconSize: new L.Point(40, 40),
        iconAnchor: new L.Point(20, 30),
        popupAnchor: new L.Point(0, -30),
    },
}) as unknown as new (options?: Partial<L.IconOptions>) => L.Icon;

export const customIcon = new AvifIcon();
export const customIconDimmed = new AvifIcon({
    className: 'dimmed',
});

export default OpenStreetMap;
