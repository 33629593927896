import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from '@mui/material';
import { FC, useEffect } from 'react';
import PlacesAutocomplete from '../../../components/formControls/PlacesAutocomplete';
import CoordinatesMap from '../../../components/map/CoordinatesMap';
import { FormikProps } from 'formik';
import { ObsListDetailsFormValues } from './ObsListDetailsForm';
import PathMap from '../../../components/map/PathMap';
import { round } from 'lodash';
import FormDarkBackground from '../../../components/formControls/FormDarkBackground';
import { EditRounded } from '@mui/icons-material';
import { getPlaceById } from '../../../services/dictProviders';
import translateErrorMessage from '../../../services/errorMessages';
import TooltipToggleButton from '../../../components/TooltipToggleButton';
import { OSM_POINT_REQUIRED_ZOOM, OSM_MUNICIPALITY_ZOOM, MapCategory } from '../../../components/map/OpenStreetMap';
import { ICoordinates } from '../../../schemas/interfaces';
import LocationFormGeocoding from './LocationFormGeocoding';
import { useJoyride } from '../../../services/joyrideContext';
import { EListObservationMode, ListObservationMode } from '../../../schemas/enums';
import NumberField from '../../../components/formControls/NumberField';

const ObsListLocationForm: FC<{ formikProps: FormikProps<ObsListDetailsFormValues> }> = (props) => {
    const { values, handleChange, handleBlur, errors, touched, setFieldValue, setTouched, setValues, setErrors } =
        props.formikProps;
    const { joyrideEvents } = useJoyride();

    useEffect(() => {
        if (!joyrideEvents) return;

        const subscription = joyrideEvents.subscribe((data) => {
            if (data.step.target === '.tour-createlist-4') handleObservationModeChange(ListObservationMode.area);
            if (data.step.target === '.tour-createlist-5') handleObservationModeChange(ListObservationMode.point);
            if (data.step.target === '.tour-createlist-6') handleObservationModeChange(ListObservationMode.line);
        });

        return () => subscription.unsubscribe();
    }, [joyrideEvents]);

    const handleObservationModeChange = (newObservationMode: EListObservationMode | null) => {
        if (!newObservationMode) return;

        const newFirstCoordinates: ICoordinates | null | undefined =
            (values.coordinates && [values.coordinates.latitude, values.coordinates.longitude]) ??
            (values.track && values.track[0]);

        setValues(
            {
                ...values,
                coordinates:
                    ([ListObservationMode.point, ListObservationMode.area] as EListObservationMode[]).includes(
                        newObservationMode,
                    ) && newFirstCoordinates
                        ? {
                              latitude: newFirstCoordinates[0],
                              longitude: newFirstCoordinates[1],
                          }
                        : null,
                distanceCovered: null,
                _Distance: null,
                track:
                    ([ListObservationMode.line] as EListObservationMode[]).includes(newObservationMode) &&
                    newFirstCoordinates
                        ? [newFirstCoordinates]
                        : null,
                observationMode: newObservationMode,
            },
            false,
        );

        setTouched(
            {
                ...touched,
                coordinates: false,
                distanceCovered: false,
                _Distance: false,
                track: false,
            },
            false,
        );

        setErrors({
            ...errors,
            coordinates: undefined,
            distanceCovered: undefined,
            _Distance: undefined,
            track: undefined,
        });
    };

    const onMunicipalityChanged = (municipalityPartId: number | null) => {
        props.formikProps.setFieldValue('municipalityPartId', municipalityPartId);

        if (!municipalityPartId) return;

        getPlaceById(municipalityPartId).then((place) => {
            props.formikProps.setFieldValue('_Municipality', place, true);
        });
    };

    const onGeocodingRejected = () => {
        props.formikProps.setFieldValue('coordinates', null);
    };

    return (
        <FormDarkBackground>
            <LocationFormGeocoding onChange={onMunicipalityChanged} onGeocodingRejected={onGeocodingRejected} />
            <Grid container className="ObsListLocationForm" spacing={2.25}>
                <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                    {([ListObservationMode.point, ListObservationMode.area] as EListObservationMode[]).includes(
                        values.observationMode,
                    ) && (
                        <>
                            <Typography
                                variant="body2"
                                component="span"
                                fontWeight="bold"
                                sx={{
                                    position: 'absolute',
                                    top: '0.7em',
                                    bgcolor: 'action.selected',
                                    zIndex: 450,
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    py: 0.25,
                                    px: 1,
                                    borderRadius: 'var(--mui-shape-borderRadius)',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Zadejte souřadnice kliknutím do&nbsp;mapy
                            </Typography>
                            <CoordinatesMap
                                formikProps={props.formikProps}
                                coordsValues={values.coordinates || {}}
                                center={
                                    values.coordinates && values.coordinates.latitude && values.coordinates.longitude
                                        ? ([values.coordinates.latitude, values.coordinates.longitude] as ICoordinates)
                                        : values._Municipality &&
                                          values._Municipality.latitude &&
                                          values._Municipality.longitude
                                        ? [values._Municipality.latitude, values._Municipality.longitude]
                                        : undefined
                                }
                                zoom={
                                    values.coordinates
                                        ? OSM_POINT_REQUIRED_ZOOM
                                        : values._Municipality
                                        ? OSM_MUNICIPALITY_ZOOM
                                        : undefined
                                }
                                requiredZoom={OSM_POINT_REQUIRED_ZOOM}
                                inputNamePrefix="coordinates."
                                disableRadius
                                TextFieldProps={{
                                    size: 'small',
                                }}
                                PasteButtonProps={{
                                    size: 'small',
                                    sx: { marginTop: '2px', color: '#fff' },
                                }}
                                height="315px"
                                required={values.observationMode === ListObservationMode.point}
                                mapCategory={MapCategory.INSERT}
                            />
                        </>
                    )}
                    {values.observationMode === ListObservationMode.line && (
                        <>
                            <Typography
                                variant="body2"
                                component="span"
                                fontWeight="bold"
                                sx={{
                                    position: 'absolute',
                                    top: '0.7em',
                                    bgcolor: 'action.selected',
                                    zIndex: 450,
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    py: 0.25,
                                    px: 1,
                                    borderRadius: 'var(--mui-shape-borderRadius)',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Zadejte linii postupným klikáním do&nbsp;mapy
                            </Typography>
                            <PathMap
                                onChange={async (newCoords, distance) => {
                                    console.log('change path', newCoords);

                                    setValues({
                                        ...values,
                                        distanceCovered: distance ? round(distance) : undefined,
                                        _Distance:
                                            distance !== undefined
                                                ? values._DistanceUnit === 'km'
                                                    ? round(distance, 2) // convert to km rounded to 2 decimals
                                                    : round(distance, 0) // convert to m rounded to 0 decimals
                                                : null,
                                        coordinates: newCoords.length
                                            ? { latitude: newCoords[0][0], longitude: newCoords[0][1] }
                                            : undefined,
                                        track: newCoords,
                                    });

                                    setTouched(
                                        {
                                            ...touched,
                                            distanceCovered: true,
                                            _Distance: true,
                                            coordinates: true,
                                            track: true,
                                        },
                                        false,
                                    );
                                }}
                                coordinates={values.track}
                                height="367px"
                                center={
                                    values.track && values.track.at(-1)
                                        ? (values.track.at(-1) as ICoordinates)
                                        : values._Municipality &&
                                          values._Municipality.latitude &&
                                          values._Municipality.longitude
                                        ? [values._Municipality.latitude, values._Municipality.longitude]
                                        : undefined
                                }
                                zoom={
                                    values.track && values.track[0]
                                        ? OSM_POINT_REQUIRED_ZOOM
                                        : values._Municipality
                                        ? OSM_MUNICIPALITY_ZOOM
                                        : undefined
                                }
                            />
                            {!!errors.track && !!touched.track && (
                                <Grid item xs={12} sx={{ mt: 1.5 }}>
                                    <FormHelperText error>
                                        {translateErrorMessage(errors.track as string)}
                                    </FormHelperText>
                                </Grid>
                            )}
                            {!!errors.coordinates && !!touched.coordinates && (
                                <Grid item xs={12} sx={{ mt: 1.5 }}>
                                    <FormHelperText error>
                                        {translateErrorMessage(errors.coordinates as string)}
                                    </FormHelperText>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    <ToggleButtonGroup
                        fullWidth
                        value={values.observationMode}
                        exclusive
                        onChange={(_, newValue) => handleObservationModeChange(newValue)}
                        sx={{
                            paddingBottom: '1em',
                            '& .MuiToggleButton-root': {
                                width: 'auto',
                                flexGrow: 1,
                                bgcolor: 'primary.main',
                                color: '#fff',
                                border: 'none',
                                '&.Mui-selected': {
                                    borderBottom: '6px solid',
                                    bgcolor: 'primary.main',
                                    color: '#fff',
                                    borderBottomColor: 'secondary.main',
                                },
                                '&:hover': {
                                    bgcolor: 'primary.main',
                                },
                            },
                        }}
                    >
                        <TooltipToggleButton
                            value={ListObservationMode.area}
                            TooltipProps={{
                                title: 'Využijte např. pokud jste navštívili nějakou obec, ale nepamatujete si konkrétní ulice, kterými jste šli.',
                                placement: 'top',
                            }}
                        >
                            Oblast
                        </TooltipToggleButton>
                        <TooltipToggleButton
                            value={ListObservationMode.point}
                            TooltipProps={{
                                title: 'Využijete např. pokud jste  sledovali rybník z\xa0jediného místa bez\xa0přemisťování.',
                                placement: 'top',
                            }}
                        >
                            Bod
                        </TooltipToggleButton>
                        <TooltipToggleButton
                            value={ListObservationMode.line}
                            TooltipProps={{
                                title: 'Využijte pro\xa0zadání několika souřadnic vycházky. Takové zadání vycházky je nejpřesnější, a tudíž i nejvíce cenné.',
                                placement: 'top',
                            }}
                        >
                            Linie
                        </TooltipToggleButton>
                    </ToggleButtonGroup>
                    <Grid container spacing={2.25}>
                        <Grid item xs={12}>
                            <Tooltip title="Název rybníka či oblasti, pokud nějaký název má" placement="top">
                                <TextField
                                    id="siteName"
                                    name="siteName"
                                    label="Název lokality"
                                    value={values.siteName || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={!!errors.siteName && !!touched.siteName}
                                    helperText={
                                        !!errors.siteName &&
                                        !!touched.siteName &&
                                        translateErrorMessage(errors.siteName)
                                    }
                                    fullWidth
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                            <PlacesAutocomplete
                                id="municipalityPartId"
                                name="municipalityPartId"
                                label="Obec (část obce)"
                                placeholder="Vyberte ze&nbsp;seznamu"
                                required
                                // filter only municipalities
                                optionsFilter={(options) => options.filter((option) => option.type === 6)}
                                value={values.municipalityPartId || null}
                                onChange={(value) => onMunicipalityChanged(value as number)}
                                onBlur={handleBlur}
                                TextFieldProps={{
                                    error: !!touched.municipalityPartId && !!errors.municipalityPartId,
                                    helperText:
                                        !!touched.municipalityPartId &&
                                        !!errors.municipalityPartId &&
                                        translateErrorMessage(errors.municipalityPartId),
                                }}
                            />
                        </Grid>
                        {(values.observationMode === ListObservationMode.line ||
                            values.observationMode === ListObservationMode.area) && (
                            <Grid item xs={12} md={9} display="flex" sx={{ gap: '1em' }}>
                                <NumberField
                                    id="_Distance"
                                    name="_Distance"
                                    disabled={values.observationMode === ListObservationMode.line}
                                    value={values._Distance || ''}
                                    onChange={(e) => {
                                        const value = parseFloat(e.target.value);

                                        if (!value) {
                                            setValues({
                                                ...values,
                                                _Distance: null,
                                                distanceCovered: null,
                                            });

                                            setTouched(
                                                {
                                                    ...touched,
                                                    _Distance: true,
                                                    distanceCovered: true,
                                                },
                                                false,
                                            );

                                            return;
                                        }
                                        const multiplier = values._DistanceUnit === 'km' ? 1000 : 1;

                                        setValues({
                                            ...values,
                                            _Distance: value,
                                            distanceCovered: round(value * multiplier, 0),
                                        });

                                        setTouched(
                                            {
                                                ...touched,
                                                _Distance: true,
                                                distanceCovered: true,
                                            },
                                            false,
                                        );
                                    }}
                                    onBlur={handleBlur}
                                    label="Odhad ujité vzdálenosti"
                                    error={!!errors.distanceCovered && !!touched.distanceCovered}
                                    helperText={
                                        !!errors.distanceCovered &&
                                        !!touched.distanceCovered &&
                                        translateErrorMessage(errors.distanceCovered)
                                    }
                                    sx={{ flexGrow: 1 }}
                                    min={0}
                                    step={0.1}
                                />
                                <FormControl sx={{ minWidth: [90, 120] }}>
                                    <Select
                                        id="_DistanceUnit"
                                        name="_DistanceUnit"
                                        disabled={values.observationMode === ListObservationMode.line}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setFieldValue('_DistanceUnit', value);

                                            if (!values.distanceCovered) return;

                                            if (value !== values._DistanceUnit) {
                                                setFieldValue(
                                                    'distanceCovered',
                                                    value === 'km'
                                                        ? round(values.distanceCovered * 1000, 0)
                                                        : round(values.distanceCovered / 1000, 0),
                                                );
                                            }
                                        }}
                                        value={values._DistanceUnit || ''}
                                        onBlur={handleBlur}
                                        label="Jednotka"
                                        required
                                        fullWidth
                                        color="secondary"
                                    >
                                        <MenuItem value="m">m</MenuItem>
                                        <MenuItem value="km">km</MenuItem>
                                    </Select>
                                    <InputLabel id="CountType">Jednotka</InputLabel>
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                id="siteNote"
                                name="siteNote"
                                label={
                                    <>
                                        <EditRounded sx={{ color: 'action.active' }} />
                                        Poznámka k&nbsp;lokalitě
                                    </>
                                }
                                multiline
                                rows={2}
                                fullWidth
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.siteNote || ''}
                                error={!!errors.siteNote && !!touched.siteNote}
                                helperText={
                                    !!errors.siteNote && !!touched.siteNote && translateErrorMessage(errors.siteNote)
                                }
                                sx={{
                                    '& .MuiInputLabel-root': {
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                        '&:not(.MuiInputLabel-shrink)': {
                                            top: '-0.2rem',
                                        },
                                        '&.MuiInputLabel-shrink .MuiSvgIcon-root': {
                                            color: 'secondary.main',
                                        },
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FormDarkBackground>
    );
};

export default ObsListLocationForm;
