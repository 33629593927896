import { useFormikContext } from 'formik';
import { FC, useState } from 'react';
import { ObsListDetailsFormValues } from './ObsListDetailsForm';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ICoordinates } from '../../../schemas/interfaces';
import { useApi } from '../../../services/apiContext';
import { Alert, Snackbar } from '@mui/material';

export enum GeocodingRejectedReason {
    OUTSIDE_CZECHIA = 'OUTSIDE_CZECHIA',
    EXTERNAL_ERROR = 'EXTERNAL_ERROR',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

const LocationFormGeocoding: FC<{
    onChange: (municipalityPartId: number) => void;
    onGeocodingRejected?: () => void;
}> = ({ onChange, onGeocodingRejected }) => {
    const formik = useFormikContext<ObsListDetailsFormValues>();
    const { geocode } = useApi();
    const [failed, setFailed] = useState<GeocodingRejectedReason>();

    const coordinates = formik.values.coordinates;

    useDeepCompareEffect(() => {
        console.log('geocode', coordinates);
        if (!coordinates || !coordinates?.latitude || !coordinates?.longitude) {
            console.log('no coordinates or track, geocoding not possible');
            return;
        }

        const coords: ICoordinates | undefined =
            coordinates && coordinates.latitude && coordinates.longitude
                ? [coordinates.latitude, coordinates.longitude]
                : undefined;

        if (!coords) {
            console.error('no coordinates or track, geocoding not possible (after checks, should not happen)');
            return;
        }

        geocode(coords)
            .then((result) => {
                if (!result.success)
                    throw new Error(`Geocoding failed, but returned a 200 response of: ${JSON.stringify(result)}`);

                onChange(result.territorialUnit.municipalityPart.id);
            })
            .catch((error) => {
                console.error(error);
                setFailed(
                    error.status === 404 || error.status === 400
                        ? GeocodingRejectedReason.OUTSIDE_CZECHIA
                        : error.status === 503
                        ? GeocodingRejectedReason.EXTERNAL_ERROR
                        : GeocodingRejectedReason.UNKNOWN_ERROR,
                );
                onGeocodingRejected && onGeocodingRejected();
            });
    }, [coordinates ?? {}]);

    return (
        <Snackbar
            open={failed !== undefined}
            autoHideDuration={6000}
            onClose={(event, reason) => {
                if (reason === 'clickaway') return;
                setFailed(undefined);
            }}
        >
            <Alert severity="error">
                {failed === GeocodingRejectedReason.OUTSIDE_CZECHIA &&
                    'Zadané souřadnice neodpovídají žádné obci v\xa0České republice. Zkuste prosím zvolit jiné.'}
                {failed === GeocodingRejectedReason.EXTERNAL_ERROR &&
                    'Obec se nepodařilo dohledat kvůli chybě externí služby. Zkuste to později nebo vyberte obec ručně ze\xa0seznamu.'}
                {failed === GeocodingRejectedReason.UNKNOWN_ERROR &&
                    'Obec se nepodařilo dohledat kvůli neznámé chybě. Zkuste to později nebo vyberte obec ručně ze\xa0seznamu.'}
            </Alert>
        </Snackbar>
    );
};

export default LocationFormGeocoding;
