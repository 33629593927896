import { format, isValid, parse } from 'date-fns';
import { IObsListSearchFormValues } from '../apps/ObsListsApp/components/ObsListsSearch';
import { EDateMode } from '../apps/ObsListsApp/components/search/ObsListsSearchDate';
import { IObservationFilter } from '../schemas/interfaces';
import { EPlaceMode } from '../apps/ObsListsApp/components/search/ObsListsSearchPlace';
import { schema_count } from '../schemas/schemas';
import { isNumber, round } from 'lodash';

export const parseFormDataToFilter: (values: IObsListSearchFormValues) => IObservationFilter = (values) => {
    switch (values._dateMode) {
        case EDateMode.Range:
            if (!isValid(values._dateStart) && !isValid(values._dateEnd)) break;

            values.dateRange = {
                start: isValid(values._dateStart) ? format(values._dateStart as Date, 'yyyy-MM-dd') : undefined,
                end: isValid(values._dateEnd) ? format(values._dateEnd as Date, 'yyyy-MM-dd') : undefined,
            };
            break;
        case EDateMode.Relative:
            //nothing to do since relative date is already in correct format in the `dateNDays` property
            break;
        case EDateMode.Seasonal:
            if (!isValid(values._dateStart) || !isValid(values._dateEnd)) break;

            values.dateSeason = {
                start: format(values._dateStart as Date, 'MM-dd'),
                end: format(values._dateEnd as Date, 'MM-dd'),
                yearStart: isValid(values._yearStart) ? format(values._yearStart as Date, 'yyyy') : undefined,
                yearEnd: isValid(values._yearEnd) ? format(values._yearEnd as Date, 'yyyy') : undefined,
            };
            break;
        case undefined:
            //nothing to do since the date form was not touched at all
            break;
        default:
            throw new Error(`Invalid date mode of ${values._dateMode}.`);
    }

    return values;
};

export const parseFilterToFormData: (values: IObservationFilter) => IObsListSearchFormValues = (values) => {
    let newFields: Partial<IObsListSearchFormValues> = {};
    if (values.dateRange) {
        newFields = {
            _dateMode: EDateMode.Range,
            _dateStart: values.dateRange.start ? parse(values.dateRange.start, 'yyyy-MM-dd', new Date()) : undefined,
            _dateEnd: values.dateRange.end ? parse(values.dateRange.end, 'yyyy-MM-dd', new Date()) : undefined,
        };
    } else if (values.dateNDays) {
        newFields = {
            _dateMode: EDateMode.Relative,
            dateNDays: values.dateNDays,
        };
    } else if (values.dateSeason) {
        newFields = {
            _dateMode: EDateMode.Seasonal,
            _dateStart: values.dateSeason.start ? parse(values.dateSeason.start, 'MM-dd', new Date()) : undefined,
            _dateEnd: values.dateSeason.end ? parse(values.dateSeason.end, 'MM-dd', new Date()) : undefined,
            _yearStart: values.dateSeason.yearStart
                ? parse(values.dateSeason.yearStart, 'yyyy', new Date())
                : undefined,
            _yearEnd: values.dateSeason.yearEnd ? parse(values.dateSeason.yearEnd, 'yyyy', new Date()) : undefined,
        };
    }

    if (values.coordinatesWithRadius) {
        newFields._placeMode = EPlaceMode.Coordinates;
    } else if (values.territorialUnitId) {
        newFields._placeMode = EPlaceMode.TeritorialUnit;
    }

    return {
        ...values,
        ...newFields,
    };
};

export const parseCountString: (
    count: string,
) => { min?: number; max?: number; exact: boolean; sortValue: number } | undefined = (count) => {
    const res = schema_count.safeParse(count);

    if (!res.success) return undefined;

    const parsed = res.data;

    let matches;

    if ((matches = parsed.match(/^([0-9]{1,5})$/)))
        return {
            min: parseInt(matches[1]),
            max: parseInt(matches[1]),
            exact: true,
            sortValue: 4,
        };

    if ((matches = parsed.match(/^((?:cca) ?([0-9]{1,5}))$/)))
        return {
            min: parseInt(matches[2]),
            max: parseInt(matches[2]),
            exact: false,
            sortValue: 3,
        };

    if ((matches = parsed.match(/^((?:(?:min\.?)) ?([0-9]{1,5}))$/)))
        return {
            min: parseInt(matches[2]),
            exact: false,
            sortValue: 5,
        };

    if ((matches = parsed.match(/^((?:|>) ?([0-9]{1,5}))$/)))
        return {
            min: parseInt(matches[3]) + 1,
            exact: false,
            sortValue: 6,
        };

    if ((matches = parsed.match(/^((?:(?:max\.?)) ?([0-9]{1,5}))$/)))
        return {
            max: parseInt(matches[3]),
            exact: false,
            sortValue: 0,
        };

    if ((matches = parsed.match(/^((?:<) ?([0-9]{1,5}))$/)))
        return {
            max: parseInt(matches[3]) - 1,
            exact: false,
            sortValue: 1,
        };

    if ((matches = parsed.match(/^(([0-9]{1,5})-([0-9]{1,5}))$/)))
        return {
            min: parseInt(matches[2]),
            max: parseInt(matches[3]),
            exact: true,
            sortValue: 2,
        };

    return undefined;
};

export const parseCoordinate = (input: number | string, precision = 6) => {
    const numeric = isNumber(input) ? input : parseFloat(input);
    if (isNaN(numeric)) return undefined;
    const rounded = round(numeric, 6);
    return rounded;
};
