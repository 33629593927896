import { ListAlt, MoreHoriz } from '@mui/icons-material';
import { IconButton, ListItem, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AvifBinocularsIcon } from './icons';

const TableActionsMenu: FC<{ itemId?: number; listId: string }> = ({ itemId, listId }) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);

    return (
        <>
            <IconButton
                ref={buttonRef}
                onClick={() => {
                    setOpen(!open);
                }}
                size="small"
            >
                <MoreHoriz fontSize="inherit" />
            </IconButton>
            <Menu
                anchorEl={buttonRef.current}
                open={open}
                sx={{ '& a': { textDecoration: 'none', color: 'inherit' } }}
                onClose={() => {
                    setOpen(false);
                }}
            >
                {!!itemId && (
                    <Link to={`/item/${itemId}`}>
                        <MenuItem dense>
                            <ListItemIcon>
                                <ListAlt fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Zobrazit pozorování" />
                        </MenuItem>
                    </Link>
                )}
                <Link to={`/list/${listId}`}>
                    <MenuItem dense>
                        <ListItemIcon>
                            <AvifBinocularsIcon fontSize="small" color="action" />
                        </ListItemIcon>
                        <ListItemText primary="Zobrazit vycházku" color="text" />
                    </MenuItem>
                </Link>
            </Menu>
        </>
    );
};

export default TableActionsMenu;
