import { AddLocationAltRounded, Help, LocationOnRounded, StickyNote2 } from '@mui/icons-material';
import {
    Box,
    Button,
    Grid,
    IconButton,
    Paper,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import ActivitySelect from '../../../../components/formControls/ActivitySelect';
import AgeSelect from '../../../../components/formControls/AgeSelect';
import SexSelect from '../../../../components/formControls/SexSelect';
import PoppedControl from '../../../../components/formControls/PoppedControl';
import SpeciesAutocomplete from '../../../../components/formControls/SpeciesAutocomplete';
import { TableEditor, TableEditorProps } from '../../../../components/tableEditor/TableEditor';
import { ObservationFormStepComponent, ObservationItemFormValues } from '../../ObservationFormApp';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { schema_listItem_input } from '../../../../schemas/schemas';
import ObsItemFormRowSubmenu from '../ObsItemFormRowSubmenu';
import ObsItemFormTags from '../ObsItemFormTags';
import equal from 'fast-deep-equal';
import CoordinatesMap from '../../../../components/map/CoordinatesMap';
import RareSpeciesValidator from '../RareSpeciesValidator';
import { ObsListDetailsFormValues } from '../ObsListDetailsForm';
import MediaUpload from '../MediaUpload';
import { distanceTwoPoints, normalizeObjectForComparison } from '../../../../services/helpers';
import FormDarkBackground from '../../../../components/formControls/FormDarkBackground';
import { ActivityCategory, ListItemAction, SexCategory } from '../../../../schemas/enums';
import translateErrorMessage from '../../../../services/errorMessages';
import { ICoordinates } from '../../../../schemas/interfaces';
import { MapCategory, OSM_POINT_REQUIRED_ZOOM } from '../../../../components/map/OpenStreetMap';
import { AvifAddStickyNote } from '../../../../components/icons';
import { TooltipMobile } from '../../../../components/Tooltip';
import CreateItemTour from '../../../../components/joyride/CreateItemTour';
import CreateItemDetailsTour from '../../../../components/joyride/CreateItemDetailsTour';
import ObsItemFormProjects from '../project/ObsItemFormProjects';
import ObsItemsFormMap from '../ObsItemsFormMap';
import { ExternalChangeEvent } from '../../../../components/tableEditor/TableEditorRow';
import { Subject } from 'rxjs';
import { useFullwidth } from '../FullwidthContext';
import TaxonFromDict from '../../../../components/dicts/TaxonFromDict';
import SexFromDict from '../../../../components/dicts/SexFromDict';
import AgeFromDict from '../../../../components/dicts/AgeFromDict';
import ActivityFromDict from '../../../../components/dicts/ActivityFromDict';
import TableIcons from '../../../../components/TableIcons';
import ObsItemFormRingMark from '../ObsItemFormRingMark';
import { useTaxonLanguage } from '../../../../services/taxonLanguageContext';
import TaxonLanguageSwitcher from '../../../../components/formControls/TaxonLanguageSwitcher';

export interface ObsItemsFormState {
    items: ObservationItemFormValues[];
    newItemId: number;
    hasInvalidItems: boolean;
    currentFileToUpload?: File;
    onSubmitAction?: (items: ObservationItemFormValues[]) => void;
    groupsEnabled?: boolean;
    existingGroups: number[];
    selectedItemIndex?: number;
}

const initialValues: ObservationItemFormValues = {
    id: undefined,
    taxonId: null,
    group: 1,
    coordinates: null,
    observersCoordinates: null,
    count: null,
    ageId: null,
    sexId: null,
    activityId: null,
    ringMark: null,
    note: null,
    tags: null,
    media: [],
    projects: null,
    _Media: [],
} as unknown as ObservationItemFormValues;

const ObsItemsForm: ObservationFormStepComponent = (props) => {
    const [state, setState] = useState<ObsItemsFormState>({
        items: props.initialValues?.items || [],
        groupsEnabled: !!props.initialValues?.items?.some((i) => i.group !== 1),
        existingGroups:
            props.initialValues?.items?.filter((item) => item.group).map((item) => item.group as number) ?? [],
        newItemId: 0,
        hasInvalidItems: false,
    });
    const [externalChanges, setExternalChanges] = useState<Subject<ExternalChangeEvent>>();
    const [formChanges, setFormChanges] = useState<Subject<ExternalChangeEvent>>();
    const isMobile = useMediaQuery('(pointer: coarse)');
    const { mapEnabled, enableMap, disableMap, enableFullwidth, disableFullwidth } = useFullwidth();
    const [formItemsForMap, setFormItemsForMap] = useState<ObservationItemFormValues[]>(state.items);
    const { currentLanguage } = useTaxonLanguage();

    useEffect(() => {
        const ec = new Subject<ExternalChangeEvent>();
        const fc = new Subject<ExternalChangeEvent>();
        setExternalChanges(ec);
        setFormChanges(fc);

        if (props.itemId)
            setState((state) => ({
                ...state,
                selectedItemIndex: state.items.findIndex((item) => item.id === props.itemId),
            }));

        return () => {
            ec.complete();
            fc.complete();
        };
    }, []);

    useEffect(() => {
        if (!enableFullwidth || !disableFullwidth) return;

        enableFullwidth();

        return () => {
            disableFullwidth();
        };
    }, [enableFullwidth, disableFullwidth]);

    const { items, existingGroups } = state;

    const getItemAction = (item: ObservationItemFormValues): ListItemAction | undefined => {
        if (item.id === undefined) return ListItemAction.Create;

        // if (item._Action === ListItemAction.Delete) return ListItemAction.Delete;

        const originalItem = props.originalList?.items?.find((i) => i.id === item.id);

        if (!originalItem) {
            console.error(`Item ${item.id} not found in original data which shouldn't happen.`);
            return undefined;
        }

        return equal(normalizeObjectForComparison(originalItem), normalizeObjectForComparison(item))
            ? undefined
            : ListItemAction.Update;
    };

    const handleGoNext = (items: ObservationItemFormValues[]) => {
        props.onNext && props.onNext({ items });
    };

    const handleGoBack = (items: ObservationItemFormValues[]) => {
        props.onBack && props.onBack({ items });
    };

    const handleSubmit: TableEditorProps<ObservationItemFormValues>['onSave'] = async (_, __, allItems) => {
        if (!state.onSubmitAction) {
            console.error('onSubmitAction not set, not doing anything');
            return;
        }

        const items = props.new
            ? allItems
                  .filter((item) => !item.deleted)
                  .map((item) => ({ ...item.modifiedData, _Action: ListItemAction.Create }))
            : allItems
                  .filter((i) => i.modifiedData.id || !i.deleted)
                  .map((item) => ({
                      ...item.modifiedData,
                      _Action: item.deleted ? ListItemAction.Delete : getItemAction(item.modifiedData),
                  }));

        state.onSubmitAction(items);

        setState((state) => ({ ...state, onSubmitAction: undefined }));
    };

    const handleItemSelected = (item: ObservationItemFormValues, index: number) => {
        if (isMobile || props.new) return;

        setState((state) => ({ ...state, selectedItemIndex: index }));

        setTimeout(() => {
            const selectedRow = document.getElementsByClassName('Avif--selected')[0];
            selectedRow?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        });
    };

    const handleItemMoved = (item: ObservationItemFormValues, index: number, coordinates: ICoordinates) => {
        externalChanges?.next({
            index,
            fieldName: 'coordinates',
            fieldValue: { latitude: coordinates[0], longitude: coordinates[1] },
        });
    };

    const toggleMap = () => {
        !mapEnabled ? enableMap?.() : disableMap?.();
    };

    return (
        <Grid container>
            <Grid item xs={12} alignContent="flex-end" sx={{ position: 'relative' }}>
                {!isMobile && (
                    <Button
                        color="primary"
                        variant="outlined"
                        sx={{ position: 'absolute', right: 0, top: '-45px' }}
                        onClick={() => toggleMap()}
                    >
                        {mapEnabled ? 'Skrýt mapu' : 'Zobrazit mapu'}
                    </Button>
                )}
            </Grid>
            <Grid item xs={12} md={mapEnabled ? 7 : 12} sx={{ height: '100%' }}>
                <Paper
                    className="ObsItemsForm"
                    sx={{
                        mb: 2,
                        ...(isMobile
                            ? {
                                  '& .MuiTableContainer-root': {
                                      backgroundImage:
                                          '\
                                linear-gradient(to left, transparent 0%, white 75%),\
                                linear-gradient(to right, transparent 0%, white 75%),\
                                radial-gradient(100% 100% at left center, rgba(26,48,80,.75) 0%, rgba(255,255,255,0) 85%),\
                                radial-gradient(100% 100% at right center, rgba(26,48,80,.75) 0%, rgba(255,255,255,0) 85%)',
                                      backgroundPosition: 'left center, right center, left center, right center',
                                      backgroundRepeat: 'no-repeat',
                                      backgroundColor: 'white',
                                      backgroundSize: 'calc(40px * 4) 100%, calc(40px * 4) 100%, 40px 100%, 40px 100%',
                                      backgroundAttachment: 'local, local, scroll, scroll',
                                  },
                                  '& .MuiTable-root': {
                                      backgroundColor: 'rgba(255,255,255,0)',
                                  },
                              }
                            : {}),
                    }}
                >
                    <TableEditor<ObservationItemFormValues>
                        columnsNumber={8}
                        formInitialValues={initialValues}
                        validationSchema={toFormikValidationSchema(schema_listItem_input.omit({ media: true }))}
                        items={items}
                        forceEdit={!!props.new}
                        enableRevert={false}
                        floatingButtons={false}
                        hidePagination
                        disableRowHighlight
                        disableAlerts
                        hideActionsColumnForEmpty
                        pageSize={10000}
                        disableButtons={['create', 'update', 'duplicate', 'revert', 'revertAll', 'save', 'delete']}
                        triggerSubmit={state.onSubmitAction !== undefined}
                        externalChanges={externalChanges}
                        onDuplicate={
                            props.new || props.originalList?.rights?.includes('addItems')
                                ? async (duplicatedItem) => {
                                      const newItem = { ...duplicatedItem, media: [], _Media: [] };
                                      return newItem;
                                  }
                                : undefined
                        }
                        onCreate={
                            props.new || props.originalList?.rights?.includes('addItems')
                                ? async (newItem) => newItem
                                : undefined
                        }
                        onDelete={
                            props.new || props.originalList?.rights?.includes('deleteItems')
                                ? async (item) => item
                                : undefined
                        }
                        onErrors={(_, errors) => {
                            console.error('form errors', errors);
                            setState((state) => ({ ...state, hasInvalidItems: true }));
                        }}
                        onSave={handleSubmit}
                        onValuesChange={(values) => {
                            setFormItemsForMap(values);
                            props.onChange && props.onChange({ items: values });
                        }}
                        onErrorsCleared={() => {
                            setState((state) => ({ ...state, hasInvalidItems: false }));
                        }}
                        onRowClick={handleItemSelected}
                        selectedItemIndex={state.selectedItemIndex}
                        tableProps={{
                            sx: {
                                '& .MuiTableRow-root.expanded': {
                                    verticalAlign: 'top',
                                },
                                '& .MuiTableRow-root': {
                                    userSelect: 'none',
                                    cursor: mapEnabled ? 'move' : undefined,
                                },
                                '& .MuiTableRow-root.Avif--selected:not(.no-border):not(.header)': {
                                    background: 'var(--mui-palette-secondary-50)',
                                },
                                '& .MuiTableRow-root.Avif--selected:not(.no-border):not(.header):hover': {
                                    background: 'var(--mui-palette-secondary-100)',
                                },
                                '& .MuiTableRow-root.MuiTableRow-hover:hover ': {
                                    bgcolor: '#f1f1f1a0',
                                },
                                '& .MuiTableRow-root .MuiTableCell-root:first-of-type[colspan]': {
                                    pl: 1.75,
                                },
                                '& .MuiTableRow-root .MuiTableCell-root:nth-of-type(2)': {
                                    pl: 1.75,
                                },
                                '& .MuiTableRow-root .MuiTableCell-root:last-of-type': {
                                    pr: 1.75,
                                },
                                '& .MuiTableHead-root': {
                                    '.MuiSvgIcon-root': {
                                        position: 'relative',
                                        marginLeft: '0.25rem',
                                        top: '4px',
                                    },
                                },
                            },
                        }}
                        footerRenderer={(items) => {
                            return (
                                <TableRow sx={{ bgcolor: '#ffffffa0' }}>
                                    <TableCell colSpan={10} sx={{ borderBottom: 'none', pl: 1.75 }}>
                                        <Box sx={{ display: 'flex', gap: '2rem', alignContent: 'flex-start', py: 1 }}>
                                            <Typography variant="body1">
                                                Celkem pozorování:&nbsp;
                                                <strong>
                                                    {
                                                        items.filter(
                                                            (i) => !i.deleted && i.modifiedData.taxonId !== undefined,
                                                        ).length
                                                    }
                                                </strong>
                                            </Typography>
                                            <Typography variant="body1">
                                                Unikátních druhů:&nbsp;
                                                <strong>
                                                    {
                                                        [
                                                            ...new Set(
                                                                items
                                                                    .filter((i) => !i.deleted)
                                                                    .map((item) => item.modifiedData.taxonId),
                                                            ),
                                                        ].filter((id) => id !== undefined).length
                                                    }
                                                </strong>
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            );
                        }}
                        itemRendererView={(item) => {
                            //for some reason when switching from p2 to p3, the data can be undefined which causes an exception
                            if (!item.data) return <></>;

                            return (
                                <>
                                    <TableCell sx={{ minWidth: '200px', width: '30%', boxSizing: 'border-box' }}>
                                        <TaxonFromDict taxonId={item.data.taxonId} />
                                    </TableCell>
                                    <TableCell sx={{ minWidth: '100px', width: '10%', boxSizing: 'border-box' }}>
                                        {item.data.count ?? <em>nezadáno</em>}
                                    </TableCell>
                                    <TableCell sx={{ minWidth: '100px', width: '12.5%', boxSizing: 'border-box' }}>
                                        <SexFromDict sexId={item.data.sexId} />
                                    </TableCell>
                                    <TableCell sx={{ minWidth: '100px', width: '10%', boxSizing: 'border-box' }}>
                                        <AgeFromDict ageId={item.data.ageId} />
                                    </TableCell>
                                    <TableCell sx={{ minWidth: '100px', width: '17.5%', boxSizing: 'border-box' }}>
                                        <ActivityFromDict activityId={item.data.activityId} />
                                    </TableCell>
                                    <TableCell sx={{ minWidth: '50px', width: '5%', boxSizing: 'border-box' }}>
                                        <TableIcons note={item.data.note} align="center" popupsDisabled />
                                    </TableCell>
                                    <TableCell sx={{ minWidth: '50px', width: '5%', boxSizing: 'border-box' }}>
                                        <TableIcons media={item.data._Media} align="center" popupsDisabled />
                                    </TableCell>
                                    <TableCell sx={{ minWidth: '50px', width: '5%', boxSizing: 'border-box' }}>
                                        <TableIcons coordinates={item.data.coordinates} align="center" popupsDisabled />
                                    </TableCell>
                                </>
                            );
                        }}
                        itemRendererEdit={(item, formikProps, formId, index) => {
                            const {
                                values,
                                errors,
                                touched,
                                setFieldValue,
                                handleChange,
                                handleBlur,
                                setFieldTouched,
                            } = formikProps;

                            return (
                                <>
                                    <TableCell
                                        sx={{ maxWidth: '250px' }}
                                        colSpan={item.empty ? 10 : 1}
                                        className={item.empty ? 'tour-createitem-1' : 'tour-createitemdetails-1'}
                                    >
                                        <FormDarkBackground disabled={!item.empty}>
                                            {item.empty && <CreateItemTour />}
                                            {!item.empty && <CreateItemDetailsTour />}
                                            <SpeciesAutocomplete
                                                // for empty item the formId changes on submit so we use it here for the key
                                                // so the species autocomplete is rerendered when species is selected
                                                key={`${formId}-species`}
                                                value={values.taxonId ?? null}
                                                onChange={(value) => {
                                                    setFieldValue('taxonId', value);
                                                }}
                                                optionsFilter={(taxons) =>
                                                    taxons.filter((taxon) => taxon.genusId || taxon.id === 0)
                                                }
                                                onBlur={() => setFieldTouched('taxonId', true)}
                                                name="taxonId"
                                                label="Druh"
                                                AutocompleteProps={{
                                                    size: !item.empty ? 'small' : undefined,
                                                    disableClearable: true,
                                                    sx: {
                                                        maxWidth: item.empty
                                                            ? 'calc(100vw - 2 * 14px - 2 * 8px)' // left+right cell padding and left+right container padding
                                                            : undefined,
                                                    },
                                                }}
                                                TextFieldProps={{
                                                    InputProps: { inputProps: { form: formId } },
                                                    // only show validation error for table rows, not the add new row form
                                                    error: !item.empty && !!errors.taxonId && !!touched.taxonId,
                                                    helperText:
                                                        !item.empty &&
                                                        !!errors.taxonId &&
                                                        !!touched.taxonId &&
                                                        translateErrorMessage(errors.taxonId),
                                                }}
                                                enableLanguageSwitch={item.empty}
                                            />
                                            <Box sx={{ height: !item.empty ? '32px' : undefined, float: 'left' }}>
                                                <Box
                                                    sx={{
                                                        width: 'auto',
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        gap: '8px',
                                                        '& .MuiChip-root': { mt: 1 },
                                                        position: 'absolute',
                                                        transform: 'translate(0) scale(1)',
                                                        zIndex: '1',
                                                        willChange: 'transform, z-index',
                                                    }}
                                                >
                                                    <RareSpeciesValidator
                                                        listValues={props.initialValues as ObsListDetailsFormValues}
                                                        itemValues={item.modifiedData}
                                                        hideTags={item.empty}
                                                        formikProps={formikProps}
                                                        formId={formId}
                                                        submitOnChange={item.empty}
                                                    />
                                                    {/* Do not display in the new observation form (when only species is selected to add a new observation to the list) */}
                                                    {!item.empty && (
                                                        <ObsItemFormProjects
                                                            list={props.initialValues as ObsListDetailsFormValues}
                                                            newList={props.new}
                                                        />
                                                    )}
                                                    <ObsItemFormTags
                                                        formikProps={formikProps}
                                                        groupsEnabled={!!state.groupsEnabled}
                                                    />
                                                </Box>
                                            </Box>
                                        </FormDarkBackground>
                                    </TableCell>
                                    {!item.empty && (
                                        <>
                                            <TableCell sx={{ maxWidth: '100px' }} className="tour-createitemdetails-6">
                                                <TextField
                                                    value={values.count || ''}
                                                    name="count"
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        setFieldValue('count', value || null, false);
                                                    }}
                                                    onBlur={handleBlur}
                                                    label="Počet"
                                                    size="small"
                                                    error={!!errors.count && !!touched.count}
                                                    helperText={
                                                        !!errors.count &&
                                                        !!touched.count &&
                                                        translateErrorMessage(errors.count)
                                                    }
                                                    inputProps={{ form: formId }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ maxWidth: '100px' }} className="tour-createitemdetails-7">
                                                <SexSelect
                                                    value={values.sexId}
                                                    name="sexId"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    label="Pohlaví"
                                                    size="small"
                                                    error={!!errors.sexId && !!touched.sexId}
                                                    helperText={
                                                        !!errors.sexId &&
                                                        !!touched.sexId &&
                                                        translateErrorMessage(errors.sexId)
                                                    }
                                                    inputProps={{ form: formId }}
                                                    filterOptions={(options) =>
                                                        options.filter((o) =>
                                                            o.category.includes(SexCategory.listItemInsert),
                                                        )
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell sx={{ maxWidth: '100px' }} className="tour-createitemdetails-8">
                                                <AgeSelect
                                                    value={values.ageId}
                                                    label="Věk"
                                                    name="ageId"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    size="small"
                                                    error={!!errors.ageId && !!touched.ageId}
                                                    helperText={
                                                        !!errors.ageId &&
                                                        !!touched.ageId &&
                                                        translateErrorMessage(errors.ageId)
                                                    }
                                                    inputProps={{ form: formId }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ maxWidth: '100px' }} className="tour-createitemdetails-9">
                                                <ActivitySelect
                                                    value={values.activityId}
                                                    name="activityId"
                                                    label="Aktivita"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    size="small"
                                                    error={!!errors.activityId && !!touched.activityId}
                                                    helperText={
                                                        !!errors.activityId &&
                                                        !!touched.activityId &&
                                                        translateErrorMessage(errors.activityId)
                                                    }
                                                    inputProps={{ form: formId }}
                                                    filterOptions={(options) =>
                                                        options.filter((activity) =>
                                                            activity.category.includes(ActivityCategory.listItemInsert),
                                                        )
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell align="center" className="tour-createitemdetails-10">
                                                <PoppedControl
                                                    renderIcon={() => (
                                                        <Tooltip title="Přidat poznámku">
                                                            <IconButton
                                                                aria-label="Vložit poznámku"
                                                                color={values.note ? 'secondary' : undefined}
                                                            >
                                                                {values.note ? <StickyNote2 /> : <AvifAddStickyNote />}
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                    renderControl={() => (
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    multiline
                                                                    autoFocus
                                                                    minRows={3}
                                                                    maxRows={6}
                                                                    value={values.note || ''}
                                                                    name="note"
                                                                    onChange={(e) => {
                                                                        const value = e.target.value;
                                                                        setFieldValue('note', value, false);
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    label="Vložte poznámku k&nbsp;pozorování"
                                                                    fullWidth
                                                                    error={!!errors.note && !!touched.note}
                                                                    helperText={
                                                                        !!errors.note &&
                                                                        !!touched.note &&
                                                                        translateErrorMessage(errors.note)
                                                                    }
                                                                    inputProps={{ form: formId }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <ObsItemFormRingMark
                                                                    label="Zadejte číslo přečteného kroužku"
                                                                    controlTag
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                    title="Poznámka"
                                                    confirmButtonLabel="Uložit"
                                                    disableClickaway
                                                    onConfirmed={() => {
                                                        setFieldValue('note', values.note);
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="center" className="tour-createitemdetails-11">
                                                <MediaUpload formikProps={formikProps} formId={formId} />
                                            </TableCell>
                                            <TableCell align="center" className="tour-createitemdetails-12">
                                                <PoppedControl
                                                    renderIcon={() => (
                                                        <Tooltip title="Přidat GPS souřadnice pozorování">
                                                            <IconButton
                                                                aria-label="Přidat GPS souřadnice"
                                                                color={values.coordinates ? 'secondary' : undefined}
                                                            >
                                                                {values.coordinates ? (
                                                                    <LocationOnRounded />
                                                                ) : (
                                                                    <AddLocationAltRounded />
                                                                )}
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                    renderControl={() => {
                                                        const listCoordinates =
                                                            props.initialValues?.coordinates &&
                                                            props.initialValues?.coordinates.latitude &&
                                                            props.initialValues?.coordinates.longitude
                                                                ? ([
                                                                      props.initialValues.coordinates.latitude,
                                                                      props.initialValues.coordinates.longitude,
                                                                  ] as ICoordinates)
                                                                : undefined;

                                                        const listTrackCoordinates =
                                                            props.initialValues?.track &&
                                                            props.initialValues.track[0] &&
                                                            props.initialValues.track.at(-1)
                                                                ? ([
                                                                      (props.initialValues.track[0][0] +
                                                                          (
                                                                              props.initialValues.track.at(
                                                                                  -1,
                                                                              ) as ICoordinates
                                                                          )[0]) /
                                                                          2,
                                                                      (props.initialValues.track[0][1] +
                                                                          (
                                                                              props.initialValues.track.at(
                                                                                  -1,
                                                                              ) as ICoordinates
                                                                          )[1]) /
                                                                          2,
                                                                  ] as ICoordinates)
                                                                : undefined;

                                                        const municipalityCoordinates =
                                                            props.initialValues?._Municipality?.latitude &&
                                                            props.initialValues?._Municipality?.longitude
                                                                ? ([
                                                                      props.initialValues._Municipality.latitude,
                                                                      props.initialValues._Municipality.longitude,
                                                                  ] as ICoordinates)
                                                                : undefined;

                                                        const itemCoordinates =
                                                            values.coordinates?.latitude &&
                                                            values.coordinates?.longitude
                                                                ? ([
                                                                      values.coordinates.latitude,
                                                                      values.coordinates.longitude,
                                                                  ] as ICoordinates)
                                                                : undefined;

                                                        return (
                                                            <CoordinatesMap
                                                                coordsValues={{
                                                                    latitude: values.coordinates?.latitude,
                                                                    longitude: values.coordinates?.longitude,
                                                                }}
                                                                center={
                                                                    itemCoordinates ??
                                                                    listTrackCoordinates ??
                                                                    listCoordinates ??
                                                                    municipalityCoordinates
                                                                }
                                                                zoom={OSM_POINT_REQUIRED_ZOOM}
                                                                formikProps={formikProps}
                                                                inputNamePrefix="coordinates."
                                                                disableRadius
                                                                requiredZoom={OSM_POINT_REQUIRED_ZOOM}
                                                                backgroundCoords={
                                                                    props.initialValues?.track ??
                                                                    (props.initialValues?.coordinates &&
                                                                    props.initialValues?.coordinates.latitude &&
                                                                    props.initialValues?.coordinates.longitude
                                                                        ? [
                                                                              [
                                                                                  props.initialValues.coordinates
                                                                                      .latitude,
                                                                                  props.initialValues.coordinates
                                                                                      .longitude,
                                                                              ],
                                                                          ]
                                                                        : undefined)
                                                                }
                                                                mapCategory={MapCategory.INSERT}
                                                                onFieldChange={(field, value) => {
                                                                    formChanges?.next({
                                                                        index,
                                                                        fieldName: field,
                                                                        fieldValue: value,
                                                                    });
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                    title="GPS souřadnice pozorování"
                                                    confirmButtonLabel="Uložit"
                                                    confirmButtonDisabled={!!errors.coordinates}
                                                    disableClickaway
                                                />
                                            </TableCell>
                                        </>
                                    )}
                                </>
                            );
                        }}
                        headerRenderer={(items) => {
                            return (
                                <>
                                    {[
                                        ['Druh', '30%', 200, false, <TaxonLanguageSwitcher />],
                                        [
                                            'Počet',
                                            '10%',
                                            100,
                                            false,
                                            <TooltipMobile title="Formát: 5; min&nbsp;5; max&nbsp;5; >5; <5; 5-10, cca&nbsp;10.">
                                                <Help fontSize="small" />
                                            </TooltipMobile>,
                                        ],
                                        [
                                            'Pohlaví',
                                            '12.5%',
                                            100,
                                            false,
                                            <TooltipMobile
                                                title="V&nbsp;případě zadání páru pozor na&nbsp;počet! Kombinace počet = 2, pohlaví = pár ve&nbsp;výsledku 
                                        znamená 4 jedince. Pokud vidíte jeden pár, zadejte počet = 1, pohlaví = pár. Pokud zaznamenáte jedince stejného 
                                        druhu s&nbsp;různým pohlavím, kteří ale netvořili pár, zadejte je do&nbsp;různých řádků."
                                            >
                                                <Help fontSize="small" />
                                            </TooltipMobile>,
                                        ],
                                        [
                                            'Věk',
                                            '10%',
                                            100,
                                            false,
                                            <TooltipMobile title="V&nbsp;případě pozorování jedinců stejného druhu různého stáří, zadejte druh do&nbsp;více řádků.">
                                                <Help fontSize="small" />
                                            </TooltipMobile>,
                                        ],
                                        [
                                            'Aktivita',
                                            '17.5%',
                                            100,
                                            false,
                                            <TooltipMobile title="Při zadání hnízdní kategorie zadávejte vždy tu nejvyšší možnou.">
                                                <Help fontSize="small" />
                                            </TooltipMobile>,
                                        ],
                                        ['Pozn.', '5%', 50, true, undefined],
                                        ['Foto', '5%', 50, true, undefined],
                                        ['GPS', '5%', 50, true, undefined],
                                    ].map(([label, width, minWidth, center, icon]) => (
                                        <TableCell
                                            width={width as string}
                                            align={center ? 'center' : undefined}
                                            key={label as string}
                                            sx={{ minWidth: minWidth as number }}
                                        >
                                            {label} {icon}
                                        </TableCell>
                                    ))}
                                </>
                            );
                        }}
                        itemRendererSubmenu={(item, formikProps, actions, formId) => (
                            <ObsItemFormRowSubmenu
                                item={item}
                                formikProps={formikProps}
                                formActions={actions}
                                formId={formId}
                                existingGroups={existingGroups}
                                groupsEnabled={state.groupsEnabled}
                                onGroupsEnabled={() => {
                                    setState((state) => ({ ...state, groupsEnabled: true }));
                                }}
                                onGroupChanged={(group) => {
                                    setState((state) => ({
                                        ...state,
                                        existingGroups: [...state.existingGroups, group],
                                    }));
                                }}
                            />
                        )}
                        validationFunction={(values) => {
                            if (!values) return undefined;

                            if (!values.coordinates?.latitude && !values.coordinates?.longitude) return undefined;

                            if (!values.coordinates?.latitude || !values.coordinates?.longitude)
                                return { coordinates: 'errors.item.coordinates.oneEmpty' };

                            const listCoordinates = props.initialValues?.coordinates
                                ? ([
                                      props.initialValues.coordinates.latitude,
                                      props.initialValues.coordinates.longitude,
                                  ] as [number, number])
                                : props.initialValues?._Municipality?.latitude &&
                                  props.initialValues?._Municipality?.longitude &&
                                  ([
                                      props.initialValues._Municipality.latitude,
                                      props.initialValues._Municipality.longitude,
                                  ] as [number, number]);

                            if (!listCoordinates) return undefined;

                            if (
                                distanceTwoPoints(listCoordinates, [
                                    values.coordinates.latitude,
                                    values.coordinates.longitude,
                                ]) > (props.initialValues?._Municipality?.maxDistance ?? 5000)
                            )
                                return {
                                    coordinates: `errors.item.coordinates.tooFar.value=${
                                        props.initialValues?._Municipality?.maxDistance
                                            ? Math.round(props.initialValues._Municipality.maxDistance / 1000)
                                            : 5
                                    }`,
                                };
                        }}
                    />
                </Paper>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
                    <Button
                        onClick={() => {
                            setState((state) => ({ ...state, onSubmitAction: handleGoBack }));
                        }}
                        variant="outlined"
                        sx={{ bgcolor: 'primary.50' }}
                    >
                        Zpět
                    </Button>
                    <Button
                        onClick={() => {
                            // if (state.hasInvalidItems) return;
                            setState((state) => ({ ...state, onSubmitAction: handleGoNext }));
                        }}
                        variant="contained"
                        color="secondary"
                        // disabled={state.hasInvalidItems}
                        className="tour-createitemdetails-13"
                    >
                        Pokračovat
                    </Button>
                </Box>
            </Grid>
            {!isMobile && mapEnabled && (
                <Grid item xs={12} md={5} sx={{ pl: 1 }}>
                    <ObsItemsFormMap
                        list={props.initialValues}
                        items={formItemsForMap}
                        onItemClick={handleItemSelected}
                        onItemMoved={handleItemMoved}
                        selectedIndex={state.selectedItemIndex}
                        changeNotifier={formChanges}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default ObsItemsForm;
