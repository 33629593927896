import { FormikProps } from 'formik';
import { FC, useState, useEffect } from 'react';
import {
    MenuItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    TextField,
    Typography,
    Box,
} from '@mui/material';
import { BookmarkAddOutlined } from '@mui/icons-material';
import PoppedControl from '../../../components/formControls/PoppedControl';
import { ITag } from '../../../schemas/interfaces';
import { allDictionaryProvider } from '../../../services/dictProviders';
import { ListItemTags, TagCategory } from '../../../schemas/enums';
import { ObservationItemFormValues } from '../ObservationFormApp';
import translateErrorMessage from '../../../services/errorMessages';

const ObsItemFormTagToggle: FC<{
    formikProps: FormikProps<ObservationItemFormValues>;
    onClose?: () => void;
}> = (props) => {
    const { values, touched, errors, handleBlur, handleChange } = props.formikProps;
    const [tagsDict, setTagsDict] = useState<ITag[]>();

    useEffect(() => {
        allDictionaryProvider
            .onFirstValue()
            .then((response) =>
                setTagsDict(
                    response.tags.filter(
                        (tag) => tag.category.includes(TagCategory.listItemInsert) && tag.label !== 'ringMark',
                    ),
                ),
            );
    }, []);

    return (
        <PoppedControl
            renderIcon={() => (
                <MenuItem>
                    <ListItemIcon>
                        <BookmarkAddOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Označit pozorování jako</ListItemText>
                </MenuItem>
            )}
            renderControl={() => (
                <FormControl component="fieldset" error={!!errors.tags && !!touched.tags} sx={{ display: 'block' }}>
                    <FormGroup>
                        {!tagsDict && 'Načítám...'}
                        {tagsDict &&
                            tagsDict.map((tag) => (
                                <Box key={tag.label}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="tags"
                                                value={tag.label}
                                                checked={values.tags?.includes(
                                                    (tag.label as (typeof values.tags)[0]) || false,
                                                )}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        }
                                        label={tag.name.cs || tag.label}
                                    />
                                    {tag.label === ListItemTags.remarkable &&
                                        values.tags?.includes(tag.label as (typeof values.tags)[0]) && (
                                            <Box sx={{ pl: 4, py: 1 }}>
                                                <Typography variant="body2" component="p" sx={{ mb: 1.75 }}>
                                                    Prosím, podělte se s&nbsp;námi o&nbsp;to, čím vám pozorování připadá
                                                    zajímavé.
                                                </Typography>
                                                <TextField
                                                    multiline
                                                    minRows={3}
                                                    maxRows={6}
                                                    value={values.note || ''}
                                                    name="note"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    label="Vložte poznámku k&nbsp;pozorování"
                                                    fullWidth
                                                    error={!!errors.note && !!touched.note}
                                                    helperText={
                                                        !!errors.note &&
                                                        !!touched.note &&
                                                        translateErrorMessage(errors.note)
                                                    }
                                                    onKeyDown={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            </Box>
                                        )}
                                </Box>
                            ))}
                    </FormGroup>
                    {!!errors.tags && !!touched.tags && <FormHelperText>{errors.tags}</FormHelperText>}
                </FormControl>
            )}
            title="Označit pozorování jako:"
            confirmButtonLabel="Uložit"
            disableClickaway
            onPopClose={props.onClose}
        />
    );
};

export default ObsItemFormTagToggle;
