import { Typography, List, ListItem } from '@mui/material';
import { IPlace } from '../../../schemas/interfaces';
import { useEffect, useState } from 'react';
import { placesDictionaryProvider } from '../../../services/dictProviders';
import { format } from 'date-fns';
import { ObservationFormStepSummaryComponent } from '../ObservationFormApp';
import { ListTypes } from '../../../schemas/enums';

const ObsListDetailsSummary: ObservationFormStepSummaryComponent = (props) => {
    const values = props.data;

    if (!values) return null;

    const [placesDict, setPlacesDict] = useState<IPlace[]>();

    useEffect(() => {
        placesDictionaryProvider.onFirstValue().then((dict) => setPlacesDict(dict.items));
    }, []);

    const placeName = placesDict
        ? placesDict.find((place) => place.id == values.municipalityPartId)?.name || 'Unknown location'
        : 'Loading...';
    const formattedDate = values.date ? format(values.date, 'd. M. yyyy') : 'Není k\xa0dispozici';
    const formattedTime = (
        <>
            {!!values.timeStart && !!values.timeEnd && (
                <strong>
                    {values.timeStart}&ndash;{values.timeEnd}
                </strong>
            )}
            {!!values.timeStart && !values.timeEnd && <strong>{values.timeStart}</strong>}
            {!values.timeStart && !values.timeEnd && <em>nezadáno</em>}
        </>
    );
    const listType = values.type === ListTypes.complete ? 'Kompletní' : 'Částečný';

    return (
        <Typography variant="caption" component="div">
            <List dense sx={{ '& .MuiListItem-root': { pl: 0 } }}>
                {!!values.siteName && (
                    <ListItem>
                        Název lokality:&nbsp;<strong>{values.siteName}</strong>
                    </ListItem>
                )}
                <ListItem>
                    Obec:&nbsp;<strong>{placeName}</strong>
                </ListItem>
                <ListItem>
                    Datum:&nbsp;<strong>{formattedDate}</strong>
                </ListItem>
                <ListItem>Čas:&nbsp;{formattedTime}</ListItem>
                <ListItem>
                    Typ seznamu:&nbsp;<strong>{listType}</strong>
                </ListItem>
            </List>
        </Typography>
    );
};

export default ObsListDetailsSummary;
