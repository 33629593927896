import { FormikProps } from 'formik';
import { FC, useState, useEffect } from 'react';
import { Box, Chip } from '@mui/material';
import { allDictionaryProvider } from '../../../services/dictProviders';
import { ITag } from '../../../schemas/interfaces';
import { ObservationItemFormValues } from '../ObservationFormApp';

const ObsItemFormTags: FC<{ formikProps: FormikProps<ObservationItemFormValues>; groupsEnabled: boolean }> = (
    props,
) => {
    const values = props.formikProps.values;
    const [tagsDict, setTagsDict] = useState<ITag[]>();

    useEffect(() => {
        allDictionaryProvider.onFirstValue().then((response) => setTagsDict(response.tags));
    }, []);

    if ((!values.group || !props.groupsEnabled) && (!values.tags || !values.tags.length)) return null;

    return (
        <>
            {props.groupsEnabled && values.group && (
                <Chip label={`Skupina ${values.group}`} size="small" sx={{ bgcolor: 'primary.50' }}></Chip>
            )}
            {values.tags?.map((tagLabel) => (
                <Chip
                    label={
                        tagsDict
                            ? tagsDict.find((tag) => tag.label == tagLabel)?.name.cs || 'Unknown tag'
                            : 'Loading...'
                    }
                    size="small"
                    sx={{ bgcolor: 'primary.50' }}
                    key={tagLabel}
                    className={tagLabel}
                ></Chip>
            ))}
        </>
    );
};

export default ObsItemFormTags;
