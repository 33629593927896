import { FC, useState, useEffect } from 'react';
import { ICoordinates, IList, IListItem, IPlace } from '../../schemas/interfaces';
import { placesDictionaryProvider } from '../../services/dictProviders';
import { Marker, Polyline, Popup } from 'react-leaflet';
import CopyToClipboard from './CopyToClipboard';
import OpenStreetMap, { MapCategory, customIcon, customIconDimmed } from './OpenStreetMap';

const ObservationMap: FC<{ list: IList; item?: IListItem }> = ({ list, item }) => {
    const [state, setState] = useState<{ placesDict?: IPlace[]; municipality?: IPlace }>({});

    useEffect(() => {
        placesDictionaryProvider
            .onFirstValue()
            .then((response) => setState((state) => ({ ...state, placeDict: response.items })));
    }, []);

    useEffect(() => {
        setState((state) => ({
            ...state,
            municipality: state.placesDict?.find((place) => place.id == list?.location.territorialUnitId),
        }));
    }, [state.placesDict]);

    const itemCoords: ICoordinates | undefined =
        item?.coordinates?.latitude && item?.coordinates?.longitude
            ? [item.coordinates.latitude, item.coordinates.longitude]
            : undefined;
    const listCoords: ICoordinates | undefined =
        list.location?.coordinates?.latitude && list.location?.coordinates?.longitude
            ? [list.location?.coordinates?.latitude, list.location?.coordinates?.longitude]
            : undefined;
    const municipalityCoords: ICoordinates | undefined =
        state.municipality && state.municipality.latitude && state.municipality.longitude
            ? [state.municipality.latitude, state.municipality.longitude]
            : undefined;

    const markerCoords = itemCoords ?? (!!list.location.coordinatesSetByUser && listCoords);

    return (
        <OpenStreetMap
            center={itemCoords ?? listCoords ?? municipalityCoords}
            defaultZoom={15}
            minHeight="300px"
            maxHeight="450px"
            height="50vh"
            mapCategory={MapCategory.SEARCH}
        >
            {list.track && list.track.length && (
                <>
                    {list.track.map((coordinate, index) => (
                        <Marker position={coordinate} icon={customIconDimmed} key={index}></Marker>
                    ))}
                    <Polyline positions={list.track} pathOptions={{ color: '#5E7694' }} className="dimmed" />
                </>
            )}
            {markerCoords && (
                <Marker position={markerCoords} icon={customIcon}>
                    <Popup>
                        <CopyToClipboard
                            label="Souřadnice"
                            value={`${Math.round(markerCoords[0] * 10000000) / 10000000}, ${
                                Math.round(markerCoords[1] * 10000000) / 10000000
                            }`}
                        />
                        <br />
                        {list.location.eea10km && (
                            <CopyToClipboard label="Kód kvadrantu" value={`${list.location.eea10km}`} />
                        )}
                    </Popup>
                </Marker>
            )}
        </OpenStreetMap>
    );
};

export default ObservationMap;
