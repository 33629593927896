import { FunctionComponent, useEffect, useState } from 'react';
import DictionarySelect, { DictionarySelectPublicProps } from '../../../../components/formControls/DictionarySelect';
import { IPredefinedFilter } from '../../../../schemas/interfaces';
import { useDict } from '../../../../services/dictContext';

/**
 * Implementation of DictionarySelect for project dictionary.
 */
const PredefinedFilterSelect: FunctionComponent<DictionarySelectPublicProps<string, IPredefinedFilter>> = (props) => {
    const [separatorBeforeIndex, setSeparatorBeforeIndex] = useState<number>();
    const [filters, setFilters] = useState<IPredefinedFilter[]>();
    const { predefinedFilters } = useDict();

    useEffect(() => {
        if (!predefinedFilters) return;

        // Sort filters by type, predefined filters first
        const filters = predefinedFilters.sort((a, b) => (a.type == b.type ? 0 : a.type == 'predefined' ? -1 : 1));

        setFilters(filters);
        setSeparatorBeforeIndex(filters.findIndex((filter) => filter.type === 'userDefined'));
    }, [predefinedFilters]);

    return (
        <DictionarySelect<string, IPredefinedFilter>
            {...props}
            getDictOptions={
                new Promise<IPredefinedFilter[]>((resolve) => {
                    if (!filters) return resolve([]);

                    return resolve([
                        ...filters,
                        // Add option for a new filter
                        {
                            id: 'new',
                            name: {
                                cs: 'Nový filtr',
                                en: 'New filter',
                            },
                            type: 'userDefined',
                            parameters: {},
                        },
                    ]);
                })
            }
            getDictValues={(filters) => filters.map((filter: IPredefinedFilter) => filter.id)}
            getOptionLabel={(option, filters = [] as IPredefinedFilter[]) =>
                filters.find((filter) => filter.id === option)?.name.cs || `Unknown filter ${option}`
            }
            separatorBeforeIndex={separatorBeforeIndex}
            separatorText="Vlastní filtry"
            disableEmpty
        />
    );
};

export default PredefinedFilterSelect;
