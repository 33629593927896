import * as z from 'zod';
import {
    response_codebook_attributes,
    response_codebook_projects,
    response_codebook_territorialunits,
    response_list,
    response_list_id,
    response_observations,
    response_observations_export,
    response_observations_filters,
    response_observations_mapPoints,
    response_observations_lists,
    response_success,
    response_user,
    response_user_profile_id,
    response_web_about_language,
    response_web_faq_language,
    response_web_help_language,
    response_web_news_language,
    response_web_news_language_id,
    schema_comment,
    response_media_id,
    response_media,
    response_list_item_comment,
    response_count,
    response_web_projects_language,
    response_analytics_countDate,
} from './schemas';
import { response_identity, response_refresh, response_token } from './schemas_auth';
import { schema_observation_gallery, schema_observation_table, schema_observation_mapped } from './interfaces';

export const response_observations_table = response_observations.extend({
    items: schema_observation_table.array(),
});
export const response_observations_gallery = response_observations.extend({
    items: schema_observation_gallery.array(),
});

export type RsGetObservations = z.infer<typeof response_observations>;
export type RsGetObservationsTable = z.infer<typeof response_observations_table>;
export type RsGetExport = z.infer<typeof response_observations_export>;
export type RsGetObservationsMap = z.infer<typeof response_observations_mapPoints>;
export type RsGetObservationsGallery = z.infer<typeof response_observations_gallery>;
export type RsGetObservationsLists = z.infer<typeof response_observations_lists>;
export type RsGetList = z.infer<typeof response_list>;
export type RsGetDicts = z.infer<typeof response_codebook_attributes>;
export type RsGetProjects = z.infer<typeof response_codebook_projects>;
export type RsGetPlaces = z.infer<typeof response_codebook_territorialunits>;
export type RsGetPredefinedFilters = z.infer<typeof response_observations_filters>;
export type RsGetListById = z.infer<typeof response_list_id>;
export type RsSuccess = z.infer<typeof response_success>;
export type RsPostItemComment = z.infer<typeof response_list_item_comment>;
export type RsUpdateItemComment = z.infer<typeof response_list_item_comment>;
export type RsGetAllNewsArticles = z.infer<typeof response_web_news_language>;
export type RsGetNewsArticle = z.infer<typeof response_web_news_language_id>;
export type RsGetHelpSections = z.infer<typeof response_web_help_language>;
export type RsGetFaqSections = z.infer<typeof response_web_faq_language>;
export type RsGetAboutSections = z.infer<typeof response_web_about_language>;
export type RsGetProjectsInfo = z.infer<typeof response_web_projects_language>;
export type RsGetUserProfileById = z.infer<typeof response_user_profile_id>;
export type RsPostList = z.infer<typeof response_list>;
export type RsLogin = z.infer<typeof response_token>;
export type RsRefresh = z.infer<typeof response_refresh>;
export type RsIdentity = z.infer<typeof response_identity>;
export type RsGetMedia = z.infer<typeof response_media_id>;
export type RsPostMedia = z.infer<typeof response_media>;
export type RsGetSearchCount = z.infer<typeof response_count>;
export type RsGetCharts = z.infer<typeof response_analytics_countDate>;
export type RsGetUser = z.infer<typeof response_user>;