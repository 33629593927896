import { Box, SxProps, Typography } from '@mui/material';
import { FC, useState, useEffect } from 'react';
import Loading from '../Loading';

const LongLoadingOverlay: FC<{
    defaultText?: string;
    longLoadingText?: string;
    longLoadingTimeout?: number;
    boxSx?: SxProps;
}> = ({ defaultText, longLoadingText, longLoadingTimeout, boxSx }) => {
    const [showLongLoading, setShowLongLoading] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => setShowLongLoading(true), longLoadingTimeout ?? 20 * 1000);
        return () => clearTimeout(timeout);
    }, []);

    return (
        <Box
            className="LongLoadingOverlay"
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255,255,255,0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center',
                padding: 5,
                minHeight: '35vh',
                boxSizing: 'border-box',
                ...boxSx,
            }}
        >
            <Box
                className="LongLoadingOverlay-content"
                sx={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '1rem',
                    backgroundColor: 'rgba(255,255,255,0.5)',
                    padding: 1,
                }}
            >
                <Loading />
                <Typography
                    variant="subtitle2"
                    component="p"
                    sx={{
                        width: '500px',
                        maxWidth: '80vw',
                        display: 'inline-block',
                    }}
                >
                    {!showLongLoading && (defaultText ?? 'Probíhá načítání, prosím vydržte.')}
                    {showLongLoading &&
                        (longLoadingText ??
                            `Výsledky filtru se nedaří načíst. Zkuste prosím aktualizovat stránku. Pokud se i poté ` +
                                `setkáte se stejným problémem, ozvěte se nám na admin@birds.cz, ať to můžeme zkusit opravit. Děkujeme!`)}
                </Typography>
            </Box>
        </Box>
    );
};

export default LongLoadingOverlay;
