import { BehaviorSubject } from 'rxjs';

/**
 * Caching algorithm that ensures that the worker is only called once and the output
 * can be provided to multiple subscribers. The worker is called when the first subscriber
 * subscribes and the result is cached. Subsequent subscribers will receive the cached result.
 *
 * Functionality is implemented using the RxJS BehaviorSubject.
 *
 * @param worker The worker that is called to get the data. The worker should return a promise.
 * @param autoInit If true, the worker is called immediately. If false, the worker is called when the first subscriber subscribes.
 */
export class RequestCache<T = { value: boolean }> {
    private _subject = new BehaviorSubject<T | undefined>(undefined);
    private _initialized = false;
    private _worker: () => Promise<T>;

    constructor(worker: () => Promise<T>, autoInit = false) {
        this._worker = worker;
        if (autoInit) this._init();
    }

    private async _init() {
        this._initialized = true;
        console.log('DictionaryProvider: init');
        this._worker()
            .then((data) => {
                console.log('worker finished');
                this._subject.next(data);
            })
            .catch((err) => {
                console.log('worker failed');
                this._subject.error(err);
            });
    }

    public subscribe(callback: (value: T | undefined) => void) {
        if (!this._initialized) this._init();
        return this._subject.subscribe((value) => {
            if (value !== undefined) callback(value);
        });
    }

    public onFirstValue(): Promise<T> {
        return new Promise((resolve) => {
            this.subscribe((value) => {
                if (value !== undefined) resolve(value);
            });
        });
    }
}
