import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './authenticator';
import { useApi } from './apiContext';
import { IUser } from '../schemas/interfaces';

export type IUserContext = IUser | undefined;

export const UserContext = createContext<IUserContext>({} as IUserContext);

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider: FC<PropsWithChildren<any>> = ({ children }) => {
    const [user, setUser] = useState<IUserContext>();
    const { isLoggedIn } = useAuth();
    const { getUser } = useApi();

    useEffect(() => {
        if (isLoggedIn === undefined) return;

        getUser().then((user) => {
            setUser(user);
        });
    }, [isLoggedIn]);

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
