import {
    Button,
    Card,
    CardContent,
    CardMedia,
    CardActions,
    Container,
    Grid,
    Typography,
    CardActionArea,
    Box,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { INewsArticleSummary } from '../../schemas/interfaces';
import { useApi } from '../../services/apiContext';
import { Link } from 'react-router-dom';
import { parse, format } from 'date-fns';
import Loading from '../../components/Loading';

const NewsApp: FC = () => {
    const [articles, setArticles] = useState<INewsArticleSummary[]>();
    const api = useApi();

    useEffect(() => {
        api.getAllNewsArticles()
            .then((articles) => {
                setArticles(articles);
            })
            .catch((e) => {
                console.error(e);
            });
    }, []);

    return (
        <Container maxWidth="lg" className="NewsApp" sx={{ pt: 5.75, pb: 6.75 }}>
            <Typography variant="h2" component="h1" textAlign="center" sx={{ pb: 3.75 }} fontWeight="bold">
                Novinky
            </Typography>
            {!articles && <Loading fullPage />}
            {articles && !articles.length && (
                <Typography variant="body1" component="p">
                    Aktuálně zde nejsou žádné novinky.
                </Typography>
            )}
            {articles && articles.length && (
                <Grid container spacing={2.25}>
                    {articles.map((article) => (
                        <Grid item xs={12} md={6} lg={4} key={article.id}>
                            <Card
                                sx={{
                                    height: '100%',
                                }}
                                variant="outlined"
                            >
                                <Link to={`/news/cs/${article.id}`}>
                                    <CardMedia
                                        image={article.thumbnail}
                                        title={article.title}
                                        sx={{ height: 200, width: '100%' }}
                                    />
                                </Link>
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Link to={`/news/cs/${article.id}`} style={{ textDecoration: 'none' }}>
                                        <Typography variant="h3" component="h2" sx={{ pb: 1 }} color="primary">
                                            {article.title}
                                        </Typography>
                                    </Link>
                                    <Typography variant="body2" component="p" sx={{ pb: 1 }}>
                                        Publikováno {format(article.date, 'd. M. yyyy')}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        sx={{
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 4,
                                        }}
                                    >
                                        {article.sample}
                                    </Typography>
                                    <Link to={`/news/cs/${article.id}`} style={{ textDecoration: 'none' }}>
                                        <Button
                                            color="secondary"
                                            sx={{
                                                textDecoration: 'underline',
                                                textTransform: 'none',
                                                pl: 0,
                                            }}
                                        >
                                            Přejít na&nbsp;článek
                                        </Button>
                                    </Link>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Container>
    );
};

export default NewsApp;
