import { FC, createContext, useContext, useEffect, useState } from 'react';
import { RsIdentity } from '../schemas/responses';
import Cookies from 'js-cookie';
import apiFactory from './api';
import LoginApp from '../apps/LoginApp/LoginApp';
import { SnackbarProps, Snackbar, Alert } from '@mui/material';

export const ACCESS_TOKEN_STR = 'access_token';
export const REFRESH_TOKEN_STR = 'refresh_token';
export const ACCESS_TOKEN_LIFESPAN = 3 * 60 * 1000; // 3 minutes

const authApi = apiFactory();

export interface AuthContextProps {
    identity?: RsIdentity;
    isLoggedIn?: boolean;
    isLoggedOut?: boolean;
    actions?: {
        login: (username: string, password: string, saveCookie?: boolean) => Promise<void>;
        logout: (expired?: boolean) => Promise<void>;
        openLogin: () => void;
        reload: (expired?: boolean) => void;
    };
}

export const AuthContext = createContext<AuthContextProps>({});

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [openLogin, setOpenLogin] = useState(false);
    const [expired, setExpired] = useState(false);
    const [loggedOut, setLoggedOut] = useState(false);

    const [state, setState] = useState<AuthContextProps>({
        actions: {
            login: async (username: string, password: string, saveCookie = true) => {
                return new Promise<void>((resolve, reject) => {
                    return authApi
                        .login(username, password, saveCookie)
                        .then((response) => {
                            setState((state) => ({ ...state, isLoggedIn: true }));
                            resolve();
                        })
                        .catch((error) => reject(error));
                });
            },
            logout: async (expired = false) =>
                new Promise<void>((resolve) => {
                    return authApi
                        .logout()
                        .catch((error) => {
                            console.error('failed to logout user', error);
                            return resolve();
                        })
                        .finally(() => {
                            setState((state) => ({ ...state, isLoggedIn: false }));
                            if (expired) setExpired(true);
                            else setLoggedOut(true);
                            return resolve();
                        });
                }),
            openLogin: () => setOpenLogin(true),
            reload: () => {
                return authApi
                    .refreshToken(true)
                    .then(() => {
                        console.log('authenticator success');
                        setState((state) => ({ ...state, isLoggedIn: true }));
                    })
                    .catch(() => {
                        console.log('authenticator failed');
                        setState((state) => ({ ...state, isLoggedIn: false }));
                    });
            },
        },
    });

    // on load, try to refresh token
    useEffect(() => {
        state.actions?.reload();
    }, []);

    // whenever isLoggedIn changes, obtain user identity
    useEffect(() => {
        if (!state.isLoggedIn) return;

        authApi
            .getUserIdentity()
            .then((response) => {
                setState((state) => ({
                    ...state,
                    identity: response,
                }));
            })
            .catch((error) => {
                console.error(error);
                state.actions?.logout();
            });
    }, [state.isLoggedIn]);

    const onSnackbarClose: SnackbarProps['onClose'] = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setExpired(false);
        setLoggedOut(false);
    };

    return (
        <AuthContext.Provider value={state}>
            <LoginApp open={openLogin} onClose={() => setOpenLogin(false)} onSuccess={() => setOpenLogin(false)} />
            {children}
            {expired && (
                <Snackbar open autoHideDuration={6000} onClose={onSnackbarClose}>
                    <Alert severity="error">
                        Z&nbsp;důvodu dlouhodobé neaktivity jste byl/a odhlášen/a. Prosím, přihlašte se znovu.
                    </Alert>
                </Snackbar>
            )}

            {loggedOut && (
                <Snackbar open autoHideDuration={6000} onClose={onSnackbarClose}>
                    <Alert severity="success">Byl/a jste úspěšně odhlášen/a.</Alert>
                </Snackbar>
            )}
        </AuthContext.Provider>
    );
};

// export default {};
