import { IPlace, ITaxon } from '../schemas/interfaces';
import { RsGetDicts, RsGetProjects, RsGetPlaces } from '../schemas/responses';
import apiFactory from './api';
import { RequestCache } from './requestCache';

const api = apiFactory();

/**
 * Subscribe to this provider to get all taxons.
 */
export const taxonsDictionaryProvider = new RequestCache<ITaxon[]>(api.getTaxons, false);

/**
 * Subscribe to this provider to get all dictionaries.
 */
export const allDictionaryProvider = new RequestCache<RsGetDicts>(api.getDicts, false);

/**
 * Subscribe to this provider to get all available projects.
 */
export const projectsDictionaryProvider = new RequestCache<RsGetProjects>(api.getProjects, false);

/**
 * Subscribe to this provider to get all countries, regions, districts and municipalities.
 */
export const placesDictionaryProvider = new RequestCache<RsGetPlaces>(api.getPlaces, false);

/**
 * Subscribe to this provider to get system-defined and user-defined filters.
 */
export const predefinedFiltersDictionaryProvider = new RequestCache(api.getPredefinedFilters, false);

export const getPlaceById = (id: number) =>
    new Promise<IPlace>((resolve, reject) => {
        placesDictionaryProvider.onFirstValue().then((response) => {
            const place = response.items.find((place) => place.id === id);

            if (!place) return reject(new Error(`Place with ID of ${id} not found.`));

            return resolve(place);
        });
    });
