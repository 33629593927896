import { ForumOutlined, ImageOutlined, ListAlt, Map, StickyNote2Outlined } from '@mui/icons-material';
import { Box, IconButton, Tooltip, TooltipProps, styled, useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import Lightbox from './gallery/Lightbox';
import { format } from 'date-fns';
import { IMedia } from '../schemas/interfaces';
import Loading from '../components/Loading';
import PoppedControl from './formControls/PoppedControl';
import OpenStreetMap, { MapCategory, OSMOnClickLocationMarker } from './map/OpenStreetMap';
import { AvifBinocularsIcon } from './icons';
import { TooltipMobile } from './Tooltip';

interface ITableIconsState {
    openGallery: boolean;
}

interface ITableIconsProps {
    media?: IMedia[] | null;
    mediaLoading?: boolean;
    itemId?: number | string;
    commentsCount?: number;
    lastCommented?: Date | null;
    linkUrl?: string;
    note?: string | null;
    coordinates?: {
        latitude: number;
        longitude: number;
    } | null;
    align?: 'center' | 'flex-end' | 'flex-start';
    popupsDisabled?: boolean;
}

const TableIcons: FC<ITableIconsProps> = (props: ITableIconsProps) => {
    const isMobile = useMediaQuery('(pointer: coarse)');
    const [state, setState] = useState<ITableIconsState>({
        openGallery: false,
    });

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '0.5em',
                flexWrap: 'wrap',
                justifyContent: props.align ?? 'flex-end',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                ...(props.popupsDisabled && {
                    '& .MuiIconButton-root': {
                        cursor: 'default',
                        '&:hover': {
                            backgroundColor: 'transparent !important',
                        },
                    },
                }),
            }}
        >
            {props.mediaLoading && (
                <>
                    <Loading inline />
                </>
            )}
            {!!props.media && props.media.length > 0 && (
                <>
                    <CustomWidthTooltip
                        title={
                            <>
                                <img src={props.media[0].thumbnail} />
                            </>
                        }
                        sx={{
                            '& .MuiTooltip-tooltip': {
                                width: 200,
                                maxWidth: '90vw',
                                minWidth: '200px',
                                cursor: 'pointer',
                                ...(props.popupsDisabled && {
                                    cursor: 'default',
                                    pointerEvents: 'none',
                                }),
                            },
                            '& img': {
                                display: 'block',
                                maxWidth: '100%',
                            },
                        }}
                        PopperProps={{
                            onClick: () => {
                                setState((state) => ({
                                    ...state,
                                    openGallery: true,
                                }));
                            },
                        }}
                    >
                        <IconButton
                            size="small"
                            className="gallery-button"
                            onClick={(e) => {
                                e.stopPropagation();
                                setState((state) => ({
                                    ...state,
                                    openGallery: true,
                                }));
                            }}
                        >
                            <ImageOutlined fontSize="small" />
                        </IconButton>
                    </CustomWidthTooltip>
                    <Lightbox
                        open={!!state.openGallery}
                        images={props.media}
                        onClose={() => setState((state) => ({ ...state, openGallery: false }))}
                    />
                </>
            )}
            {!!props.note && (
                <TooltipMobile title={props.note}>
                    <IconButton size="small" sx={{ cursor: 'default' }} className="note-button">
                        <StickyNote2Outlined fontSize="small" />
                    </IconButton>
                </TooltipMobile>
            )}
            {!!props.coordinates && (
                <PoppedControl
                    disableButtons
                    renderIcon={() => {
                        if (!props.coordinates) return <></>;

                        return (
                            <Tooltip
                                title={`Souřadnice pozorování: ${props.coordinates.latitude}, ${props.coordinates.longitude}`}
                            >
                                <IconButton size="small" sx={{ cursor: 'pointer' }} className="coordinaes-button">
                                    <Map fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        );
                    }}
                    renderControl={() => {
                        if (!props.coordinates) return <></>;

                        return (
                            <Box sx={{ pt: 2 }}>
                                <OpenStreetMap
                                    height="40vh"
                                    minHeight="200px"
                                    mapCategory={MapCategory.SEARCH}
                                    center={[props.coordinates.latitude, props.coordinates.longitude]}
                                    defaultZoom={14}
                                >
                                    <OSMOnClickLocationMarker
                                        latitude={props.coordinates.latitude}
                                        longitude={props.coordinates.longitude}
                                    />
                                </OpenStreetMap>
                            </Box>
                        );
                    }}
                    disabled={props.popupsDisabled}
                />
            )}
            {!!props.commentsCount && (
                <TooltipMobile
                    title={
                        `Obsahuje ${props.commentsCount} komentářů.` +
                        (!!props.lastCommented && ` Poslední přidán ${format(props.lastCommented, 'd. M. yyyy H:ii')}.`)
                    }
                >
                    <IconButton size="small" className="comments-button">
                        <ForumOutlined fontSize="small" />
                    </IconButton>
                </TooltipMobile>
            )}
            {!!props.linkUrl && (
                <Tooltip title="Detail vycházky">
                    <Link
                        to={props.linkUrl}
                        onClick={(event) => event.stopPropagation()}
                        target="_blank"
                        className="list-button"
                    >
                        <IconButton size="small">
                            <ListAlt fontSize="small" />
                        </IconButton>
                    </Link>
                </Tooltip>
            )}
            {isMobile && props.itemId !== undefined && (
                <Tooltip title="Detail pozorování">
                    <Link
                        to={`/item/${props.itemId}`}
                        onClick={(event) => event.stopPropagation()}
                        target="_blank"
                        className="item-button"
                    >
                        <IconButton size="small">
                            <AvifBinocularsIcon fontSize="small" color="action" />
                        </IconButton>
                    </Link>
                </Tooltip>
            )}
        </Box>
    );
};

// I honestly don't know why this is necessary but it seems that the popper classes somehow
// modify the way the tooltip is positioned and it magically works
const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <TooltipMobile {...props} classes={{ popper: className }} />
))({});

export default TableIcons;
