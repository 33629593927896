import {
    Autocomplete,
    AutocompleteProps,
    Box,
    MenuItem,
    MenuItemProps,
    TextField,
    TextFieldProps,
} from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { normalizeString } from '../../services/helpers';

export interface DictionaryAutocompletePublicProps<OptionType, IdType> {
    onChange: (value: IdType | IdType[] | null) => void;
    onBlur: AutocompleteProps<OptionType, boolean, boolean, false>['onBlur'];
    value: IdType | IdType[] | null;
    name?: string;
    multiple?: boolean;
    label?: string;
    placeholder?: string;
    id?: string;
    required?: boolean;
    AutocompleteProps?: Partial<AutocompleteProps<OptionType, boolean, boolean, false>>;
    TextFieldProps?: Partial<TextFieldProps>;
    loadingText?: string;
    emptyInputText?: string;
    noResultsText?: string;
    optionsLimit?: number;
    optionsFilter?: (options: OptionType[]) => OptionType[];
    highlightWhenFilled?: boolean;
    groupBy?: (option: OptionType) => string;
}

interface DictionaryAutocompleteProps<OptionType, IdType>
    extends DictionaryAutocompletePublicProps<OptionType, IdType> {
    getOptionLabel: (option: OptionType) => string;
    getOptionById: (id: IdType, allOptions: OptionType[]) => OptionType | undefined | null;
    getDictOptions: Promise<OptionType[]>;
    stringifyOption?: (option: OptionType) => string;
}

/**
 * Generic autocomplete component that uses a dictionary provider to load the options. The options are loaded
 * only once via the getDictOptions promise. The options are filtered based on the input value. The filtering
 * is done by simple string lookup function. The value used during search is by default retreieved by the
 * getOptionLabel function. This can be changed by providing a custom stringifyOption function which allows
 * for more complex search & different display value. Additionaly, the options can be filtered by providing
 * a custom optionsFilter function that filters the options before they are displayed. Maximum number of `optionsLimit`
 * options can be displayed at once - the default is 100. When user types, the filtering is memoized and debounced
 * to provide better performance.
 *
 * @param props.onChange Function that is called when the value changes.
 * @param props.onBlur Function that is called when the input field loses focus.
 * @param props.value Current value of the input field (it only expects the id of the selected option).
 * @param props.name Name of the input field.
 * @param props.multiple Whether the input field should allow multiple values.
 * @param props.label Label of the input field.
 * @param props.placeholder Placeholder of the input field.
 * @param props.id Id of the input field.
 * @param props.required Whether the input field is required.
 * @param props.AutocompleteProps Additional props that will be passed to the Autocomplete component.
 * @param props.TextFieldProps Additional props that will be passed to the TextField component.
 * @param props.loadingText Text that will be displayed when the options are being loaded.
 * @param props.emptyInputText Text that will be displayed when the input field is empty.
 * @param props.noResultsText Text that will be displayed when the search yields no results.
 * @param props.optionsLimit Maximum number of options that can be displayed at once.
 * @param props.optionsFilter Function that filters the options before they are displayed.
 * @param props.getOptionLabel Function that returns the label of an option.
 * @param props.getOptionById Function that returns an option based on its id.
 * @param props.getDictOptions Promise that returns all available options.
 * @param props.stringifyOption Function that returns a string that will be used for searching.
 *                              To enable custom search, supply multiple values divided by '|'. Each of the values
 *                              will be examined separately by comparing it with user's input using the startsWith function.
 */
const DictionaryAutocomplete = <OptionType extends { id: IdType }, IdType extends string | number>(
    props: DictionaryAutocompleteProps<OptionType, IdType>,
) => {
    /**
     * Array of all available options. This is used for filtering.
     */
    const [allOptions, setAllOptions] = useState<OptionType[] | undefined>(undefined);
    /**
     * Current value of the input field.
     */
    const [inputValue, setInputValue] = useState<string>('');
    /**
     * Array of options that will be displayed in the dropdown.
     */
    const [options, setOptions] = useState<OptionType[]>([]);
    /**
     * Text that will be displayed when the search yields no results or when the options are being loaded.
     */
    const [emptyText, setEmptyText] = useState<string>(props.loadingText || 'Loading...');
    /**
     * Current value of the input field. This is used for controlled components.
     */
    const [valueOption, setValueOption] = useState<OptionType | null | OptionType[]>(props.multiple ? [] : null);

    // This effect will run only once when the component is mounted. It will load all the options from the
    // dictionary provider and store them in the state.
    useEffect(() => {
        props.getDictOptions.then((options) => {
            setAllOptions(options);
        });
    }, []);

    // useMemo will cache the function depending on the allOptions and props.optionsFilter dependencies.
    // This speeds up the filtering of options. debounce is used to prevent the filtering from happening
    // too often e.g. when the user is typing fast.
    const filterOptions = useMemo(
        () =>
            debounce((inputValue: string) => {
                if (!allOptions) return setOptions([]);

                const userFilteredOptions = props.optionsFilter ? props.optionsFilter(allOptions) : allOptions;

                if (inputValue === '') return setOptions(userFilteredOptions.slice(0, props.optionsLimit ?? 100));

                if (
                    valueOption &&
                    typeof valueOption === 'object' &&
                    props.getOptionLabel(valueOption as OptionType) === inputValue
                ) {
                    const valueIndex = allOptions.findIndex((option) => option.id === (valueOption as OptionType).id);

                    setTimeout(() => {
                        const minIndex = Math.max(0, valueIndex - 30);
                        const maxIndex = Math.min(allOptions.length, valueIndex + 30);

                        setOptions(userFilteredOptions.slice(minIndex, maxIndex));
                    });
                } else {
                    const matchedOptions = userFilteredOptions
                        .filter((option) => {
                            const stringified = props.stringifyOption
                                ? props.stringifyOption(option)
                                : props.getOptionLabel(option);
                            const keywords = stringified.split('|');

                            return keywords.some((keyword) =>
                                normalizeString(keyword).startsWith(normalizeString(inputValue)),
                            );
                        })
                        .slice(0, props.optionsLimit ?? 100);

                    setTimeout(() => setOptions(matchedOptions));
                }
            }),
        [allOptions, props.optionsFilter, valueOption],
    );

    // This effect will run when the allOptions or inputValue changes. This way, the options will be filtered
    // when the user types something or when the options are loaded for the first time. Actual update of the
    // options is done in the filterOptions function which is memoized and debounced.
    useEffect(() => {
        if (!allOptions) {
            setEmptyText(props.loadingText || 'Loading...');
            return undefined;
        }

        setEmptyText(
            inputValue === ''
                ? props.emptyInputText || 'Start typing to search through dicts.'
                : props.noResultsText || 'No results. Try different query.',
        );

        filterOptions(inputValue);
    }, [inputValue, allOptions, props.optionsFilter]);

    // This effect will run when the value changes. This way, the valueOption will be updated when the value
    // is changed from outside of the component e.g. when the value would be changed by a different component
    // e.g. when form is reset. The effect will obtain the option based on the provided id.
    useEffect(() => {
        if (!allOptions || props.value === null || props.value === undefined)
            return setValueOption(props.multiple ? [] : null);

        if (props.multiple) {
            return setValueOption(
                (props.value as IdType[]).map((id) => props.getOptionById(id, allOptions) as OptionType),
            );
        } else {
            return setValueOption(props.getOptionById(props.value as IdType, allOptions) || null);
        }
    }, [props.value, allOptions]);

    return (
        <Autocomplete<OptionType, boolean, boolean, false>
            {...props.AutocompleteProps}
            multiple={props.multiple || false}
            id={props.name || 'dicts'}
            onChange={(_, value) =>
                props.onChange(
                    value === null || value === undefined || (value as any) === ''
                        ? null
                        : props.multiple
                        ? (value as OptionType[]).map((option) => option.id)
                        : (value as OptionType).id,
                )
            }
            onBlur={props.onBlur}
            value={valueOption}
            options={options}
            filterOptions={(x) => x}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            autoComplete
            // filterSelectedOptions
            noOptionsText={emptyText}
            onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
            }}
            groupBy={props.groupBy}
            getOptionLabel={props.getOptionLabel}
            renderOption={
                props.AutocompleteProps?.renderOption
                    ? props.AutocompleteProps?.renderOption
                    : (optionProps, option) => (
                          <MenuItem
                              component="li"
                              {...optionProps}
                              key={option.id}
                              sx={{
                                  '&.MuiMenuItem-root[aria-selected="true"]': {
                                      bgcolor: 'secondary.50',
                                  },
                              }}
                          >
                              {props.getOptionLabel(option)}
                          </MenuItem>
                      )
            }
            renderInput={(params: any) => (
                <TextField
                    {...params}
                    color={props.TextFieldProps?.color || props.AutocompleteProps?.color}
                    className={
                        params.className +
                        (props.highlightWhenFilled &&
                        ((Array.isArray(valueOption) && valueOption.length > 0) ||
                            (!Array.isArray(valueOption) && !!valueOption))
                            ? ' nonEmpty'
                            : '')
                    }
                    {...props.TextFieldProps}
                    InputProps={{
                        ...props.TextFieldProps?.InputProps,
                        ...params.InputProps,
                        startAdornment: (
                            <>
                                {props.TextFieldProps?.InputProps?.startAdornment}
                                {params.InputProps?.startAdornment}
                            </>
                        ),
                        endAdornment: (
                            <>
                                {props.TextFieldProps?.InputProps?.endAdornment}
                                {params.InputProps?.endAdornment}
                            </>
                        ),
                        inputProps: {
                            ...props.TextFieldProps?.InputProps?.inputProps,
                            ...props.TextFieldProps?.inputProps,
                            ...params.InputProps.inputProps,
                            ...params.inputProps,
                            form: props.TextFieldProps?.InputProps?.inputProps?.form,
                        },
                    }}
                    id={props.id}
                    name={props.name || 'dicts'}
                    label={props.label || 'Filter dicts by their name'}
                    placeholder={props.placeholder || 'Select one or more dicts'}
                    required={props.required || false}
                />
            )}
        />
    );
};

export default DictionaryAutocomplete;
